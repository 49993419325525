import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ConfigurationService } from '../configuration/configuration.service';
import { LoggerService } from '../logging/logger.service';
import { ServerVarsService } from '../server-vars/server-vars.service';
import { PlatformService } from '../universal/platform.service';
import { BasicAuthService } from './basic-auth.service';
import { DefaultApiService } from './default-api.service';
import { MockedApiService } from './mocked-api.service';

@Injectable({
    providedIn: 'root',
    useFactory: apiServiceFactory,
    deps: [
        HttpClient,
        ConfigurationService,
        UrlSerializer,
        LoggerService,
        BasicAuthService,
        ServerVarsService,
        PlatformService,
        NgZone
    ]
})
export abstract class ApiService {
    public abstract get<T>(
        requestPath: string,
        params?: { [id: string]: any } | HttpParams,
        options?: Record<string, unknown>
    ): Observable<T>;

    public abstract post<T>(
        requestPath: string,
        params?: { [id: string]: any } | HttpParams,
        body?: { [id: string]: any },
        options?: Record<string, unknown>
    ): Observable<T>;

    public abstract put<T>(
        requestPath: string,
        params?: { [id: string]: any } | HttpParams,
        body?: { [id: string]: any },
        options?: Record<string, unknown>
    ): Observable<T>;

    public abstract delete<T>(
        requestPath: string,
        params?: { [id: string]: any } | HttpParams,
        body?: { [id: string]: any },
        options?: Record<string, unknown>
    ): Observable<T>;

    public abstract download(requestPath: string, params?: { [id: string]: any } | HttpParams): Observable<Blob>;
}

export function apiServiceFactory(
    http: HttpClient,
    config: ConfigurationService,
    urlSerializer: UrlSerializer,
    logger: LoggerService,
    basicAuth: BasicAuthService,
    serverVars: ServerVarsService,
    platform: PlatformService,
    ngZone: NgZone
) {
    if (environment.useMockApi) {
        return new MockedApiService(urlSerializer, logger);
    } else {
        return new DefaultApiService(http, config, logger, basicAuth, serverVars, platform, ngZone);
    }
}
