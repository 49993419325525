<div class="container p-3">
    <h1>
        Leistungsbericht <span *ngIf="kpiData?.merchantName">({{ kpiData.merchantName }})</span>
    </h1>

    <ng-template #componentPlaceholderAbove phxDynamicComponent></ng-template>

    <div *ngIf="loading" class="text-center">
        <ngb-alert type="secondary" [dismissible]="false">Daten werden geladen...</ngb-alert>
    </div>

    <div *ngIf="!loading && hasError" class="text-center">
        <ngb-alert type="danger" [dismissible]="false">Fehler beim Laden der Daten.</ngb-alert>
    </div>

    <div *ngIf="!loading && !hasError && !kpiData" class="text-center">
        <ngb-alert type="warning" [dismissible]="false">Keine Daten vorhanden.</ngb-alert>
    </div>

    <div *ngIf="!loading && kpiData" class="overflow-auto">
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col" phxSortable="month" (phxSort)="onSort($event)">Monat</th>
                    <th scope="col" class="text-end" phxSortable="revenue" (phxSort)="onSort($event)">Umsatz</th>
                    <th scope="col" class="text-end" phxSortable="ordersCompleted" (phxSort)="onSort($event)">
                        Bestellungen
                    </th>
                    <th scope="col" class="text-end" phxSortable="averageOrderValue" (phxSort)="onSort($event)">
                        Ø&nbsp;Warenkorb
                    </th>
                    <th scope="col" class="text-end" phxSortable="cancellationRate" (phxSort)="onSort($event)">
                        Stornowertgrad
                    </th>
                    <th scope="col" class="text-end" phxSortable="deliveryReliability" (phxSort)="onSort($event)">
                        Liefertreue
                    </th>
                    <th scope="col" class="text-end" phxSortable="cancellationPenalty" (phxSort)="onSort($event)">
                        Stornowertgrad-Pönale
                    </th>
                    <th
                        scope="col"
                        class="text-end"
                        phxSortable="deliveryReliabilityPenalty"
                        (phxSort)="onSort($event)"
                    >
                        Liefertreue-Pönale
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let kpiMonth of sortedKpiMonths; index as i">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ kpiMonth.month | date : 'MMM yyyy' }}</td>
                    <td class="text-end">{{ kpiMonth.revenue | currency : 'EUR' }}</td>
                    <td class="text-end">{{ kpiMonth.ordersCompleted | number }}</td>
                    <td class="text-end">{{ kpiMonth.averageOrderValue | currency : 'EUR' }}</td>
                    <td class="text-end">{{ kpiMonth.cancellationRate | percent : '1.2-2' }}</td>
                    <td class="text-end">{{ kpiMonth.deliveryReliability | percent : '1.2-2' }}</td>
                    <td class="text-end">{{ kpiMonth.cancellationPenalty | currency : 'EUR' }}</td>
                    <td class="text-end">{{ kpiMonth.deliveryReliabilityPenalty | currency : 'EUR' }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div [hidden]="hasError || loading">
        <ng-template #componentPlaceholderBelow phxDynamicComponent></ng-template>
    </div>
</div>
