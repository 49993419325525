import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError } from './api-error';

@Injectable({
    providedIn: 'root'
})
export class CustomErrorOverridesService {
    customErrorOverrides(response: HttpErrorResponse, apiError: ApiError) {}
}
