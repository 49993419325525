import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PlatformService } from '../universal';
import { NavigationService } from './navigation.service';

@Injectable()
export class CustomStabilizationHttpInterceptor implements HttpInterceptor {
    constructor(private navigationService: NavigationService, private platform: PlatformService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.platform.isServer) {
            return next.handle(request);
        }

        // All HTTP requests make the Angular app unstable
        this.navigationService.isStable$.next(false);

        // After the response we wait until it is stable again (all HTTP requests are done)
        return next.handle(request).pipe(
            tap({
                next: () => {
                    this.navigationService.startCustomStabilizationCheck();
                },
                error: () => {
                    this.navigationService.startCustomStabilizationCheck();
                }
            })
        );
    }
}
