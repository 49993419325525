import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { defer, fromEvent, interval, merge } from 'rxjs';
import { buffer, filter, materialize, mergeMap, take } from 'rxjs/operators';
import { ConfigurationService } from '../configuration/configuration.service';
import { ApiError } from '../error-handling/api-error';
import { PlatformService } from '../universal/platform.service';
import { Dictionary } from '../utils/dictionary.type';
import { BasicLoggerService } from './basic-logger.service';
import { LogLevel, LogMessage } from './log';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    static readonly LOG_LEVELS_ORDERED: LogLevel[] = ['trace', 'debug', 'info', 'warn', 'error', 'fatal', 'off'];
    private isSSR: boolean;

    constructor(
        private http: HttpClient,
        private config: ConfigurationService,
        private platform: PlatformService,
        private basicLogger: BasicLoggerService,
        private zone: NgZone
    ) {
        this.isSSR = this.platform.isServer;

        if (!this.isSSR) {
            // this.basicLogger.logQueueSubscription.unsubscribe();
            // only needed when running on the client, when the app is rendered on the server it logs directly
            const appClosing$ = fromEvent(window, 'beforeunload');
            const logBufferInterval$ = defer(() =>
                interval(this.config.isLoaded ? this.config.get('logBufferInterval') : 5000)
            );
            const logFlushes$ = merge(logBufferInterval$, appClosing$);
            // interval, setTimeout, etc. will cause the app to never be stable
            // and the service worker is only registered after the app stabilizes
            this.zone.onStable
                .pipe(
                    // NOTE: Contrary to what documentation says to onStable, it emits multiple times
                    take(1),
                    mergeMap(() => this.basicLogger.logQueueObservable.pipe(buffer(logFlushes$))),
                    filter(logs => logs.length > 0),
                    mergeMap(logs => this.http.post(`./log`, logs).pipe(materialize()))
                )
                .subscribe(notification => {
                    if (notification && notification.kind === 'E') {
                        console.error('Error while posting log messages.', notification.error);
                    }
                });

            // make log function available to plain, injected javascript
            window['phxLogError'] = this.error.bind(this);
        }
    }

    error(error: Error | ApiError, ...messages: LogMessage[]) {
        if (error) {
            if (error instanceof ApiError) {
                // Mark this error as already logged/handled to inform our custom global error handler to not handle it again
                // Otherwise it can happen in certain conditions (full-auth required on route resolve, deeplinking to a auth-required route that i am not allowed to, ...)
                // that the error is logged twice (see also `error-handler.service.ts`)
                error.isAlreadyHandled = true;
            }

            if (error['ignore']) {
                return;
            }

            // make error stack be part of serialization output
            Object.defineProperties(error, {
                stack: {
                    enumerable: true
                }
            });
        }

        if (messages && typeof messages[0] !== 'string' && error?.message) {
            // there was no custom error message string supplied as first parameter, so we take the message from the error itself
            messages.unshift(error.message);
        }

        this.logWithBasicLogger('error', messages, { error });
    }

    debug = (...messages: LogMessage[]) => this.logWithBasicLogger('debug', messages);

    info = (...messages: LogMessage[]) => this.logWithBasicLogger('info', messages);

    trace = (...messages: LogMessage[]) => this.logWithBasicLogger('trace', messages);

    warn = (...messages: LogMessage[]) => this.logWithBasicLogger('warn', messages);

    getAdditionalLogData(): Dictionary<any> {
        return {};
    }

    private logWithBasicLogger(level: LogLevel, messages: LogMessage[], extras = {}) {
        this.basicLogger.log(
            messages,
            level,
            { ...extras, ...this.getAdditionalLogData() },
            this.isClientLogLevelAllowed(level),
            this.isLogLevelAllowed(level),
            this.logWithoutLogEndpointAnyway()
        );
    }

    private isClientLogLevelAllowed(level: LogLevel) {
        if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('clientLogLevel')) {
            return this.isLogLevelAllowed(level, sessionStorage.getItem('clientLogLevel') as LogLevel);
        } else {
            return this.isLogLevelAllowed(
                level,
                (this.config.isLoaded && this.config.get('clientLogLevel')) || 'error'
            );
        }
    }

    private isLogLevelAllowed(level: LogLevel, treshold?: LogLevel) {
        const levels = LoggerService.LOG_LEVELS_ORDERED;
        const configuredTreshold: LogLevel = this.config.isLoaded ? this.config.get('logLevel') : 'info';

        // when the log level is equal to or above the configured log level
        return levels.indexOf(level) >= levels.indexOf(treshold || configuredTreshold);
    }

    private logWithoutLogEndpointAnyway() {
        return this.config.isLoaded && this.config.get('logWithoutLogEndpoint');
    }
}
