import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { LoggerService } from '../logging/logger.service';
import { ComponentContext } from './component-context';
import { ContentItem } from './content-item';
import { CustomCssService } from './custom-css.service';

@Component({
    selector: 'phx-base-content-item',
    template: ``,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseContentItemComponent implements OnInit, OnDestroy {
    contentItem: ContentItem;

    // made readonly to prevent that the passed along component context is overridden (properties can be added though)
    readonly componentContext?: ComponentContext = {};

    @HostBinding('class') cmsIdClass: string;

    constructor(protected logger: LoggerService, protected customCssService?: CustomCssService) {}

    ngOnInit(): void {
        if (this.contentItem) {
            this.cmsIdClass = 'phx-cms-id-' + this.contentItem.metadata.id;

            if (this.contentItem.fields['customCss'] && this.customCssService) {
                this.customCssService.injectCss(
                    this.contentItem.fields['customCss'].toString(),
                    this.componentContext?.['isGlobalInjection'] ? null : this.cmsIdClass
                );
            }

            this.logger.debug(
                `[BASE-CONTENT-ITEM] init, name: ${this.contentItem.metadata && this.contentItem.metadata.name}, id: ${
                    this.contentItem.metadata && this.contentItem.metadata.id
                }, type: ${this.contentItem.metadata && this.contentItem.metadata.type}`
            );
        }
    }

    ngOnDestroy(): void {
        this.customCssService?.removeCustomStyleElement(this.cmsIdClass);
    }

    /**
     * Checks if a given ContentItem has an ancestor of a certain type
     *
     * @param type Component type
     * @param onlyCheckParent if we want to only check direct parents
     * @param ignoreRestrictionContainer if we want to include or exclude the restrictionContainer when we are looking at direct parents
     */
    hasAncestorOfType(type: string, onlyCheckParent = false, ignoreRestrictionContainer = true): boolean {
        const ancestors = this.contentItem?.data?.parents as ContentItem[];

        if (!ancestors?.length) {
            return false;
        }

        let hasAncestorOfType = ancestors.some(ancestor => ancestor.metadata.type === type);

        if (onlyCheckParent && hasAncestorOfType) {
            const parentType = ancestors[ancestors.length - 1].metadata.type;
            if (ignoreRestrictionContainer && parentType === '__dynamicComponentRestrictionContainerComponent__') {
                hasAncestorOfType = ancestors[ancestors.length - 2].metadata.type === type;
            } else {
                hasAncestorOfType = parentType === type;
            }
        }

        return hasAncestorOfType;
    }

    isChildOfTwoColumnsComponent() {
        return this.hasAncestorOfType('componentTwoColumns');
    }
}
