export const compare = (v1: string | number, v2: string | number) => {
    const v1Null = v1 == null;
    const v2Null = v2 == null;
    if (v1Null || v2Null) {
        return v1Null !== v2Null ? (v1Null ? 1 : -1) : 0;
    }

    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

export function getObjProperty<O>(obj: O, path: string) {
    return path.split('.').reduce((o, k) => o[k], obj);
}

export const capitalizeFirstLetter = (value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1);
};
