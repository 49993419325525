export interface AdyenOnboardingUrl {
    url: string;
}

export interface AdyenOnboardingData {
    adyenAgreementId: string;
    adyenStatus: AdyenOnboardingStatus;
    accountHolderData?: {
        balancePlatform: string;
        capabilities: {
            receiveFromPlatformPayments: Capability;
            receiveFromBalanceAccount: Capability;
            sendToBalanceAccount: Capability;
            sendToTransferInstrument: Capability;
        };
        description: string;
        id: string;
        legalEntityId: string;
        primaryBalanceAccount: string;
        reference: string;
        status: AdyenAccountHolderStatus;
    };
}

export interface Capability {
    enabled: boolean;
    requested: boolean;
    allowed: boolean;
    problems?: CapabilityProblem[];
    verificationStatus: AdyenOnboardingCapabilityVerificationStatus;
}

export interface CapabilityProblem {
    entity: {
        id: string;
        type: string;
    };
    verificationErrors: CapabilityProblemError[];
}
export interface CapabilityProblemError {
    code: string;
    message: string;
    remediatingActions: [
        {
            code: string;
            message: string;
        }
    ];
    type: string;
}

export enum AdyenOnboardingStatus {
    UNREADY = 'UNREADY',
    READY_FOR_CREATION = 'READY_FOR_CREATION',
    READY_FOR_HOP = 'READY_FOR_HOP'
}
export type AdyenAccountHolderStatus = 'active' | 'inactive' | 'suspended' | 'closed';

export type AdyenOnboardingCapabilityVerificationStatus = 'valid' | 'invalid' | 'pending';

export enum CapabilityDisplayStatus {
    OK = 'ok',
    PENDING = 'pending',
    ACTION_REQUIRED = 'actionRequired',
    DEACTIVATED = 'deactivated'
}
