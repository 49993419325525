import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserModel } from '../../models/user.model';
import { Login, Logout } from '../../store/auth.actions';
import { getUser } from '../../store/auth.selectors';

@Component({
    selector: 'phx-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginFormGroup: UntypedFormGroup;
    usernameControl: UntypedFormControl;
    passwordControl: UntypedFormControl;

    user$: Observable<UserModel>;

    constructor(private store: Store, private activatedRoute: ActivatedRoute, private titleService: Title) {}

    ngOnInit(): void {
        this.user$ = this.store.select(getUser);

        // setup login form
        this.usernameControl = new UntypedFormControl(null, [Validators.required]);
        this.passwordControl = new UntypedFormControl(null, [Validators.required]);
        this.loginFormGroup = new UntypedFormGroup({
            username: this.usernameControl,
            password: this.passwordControl
        });

        this.titleService.setTitle(this.activatedRoute.snapshot.data?.['title']);
    }

    login() {
        this.store.dispatch(new Login({ username: this.usernameControl.value, password: this.passwordControl.value }));
    }

    logout() {
        this.store.dispatch(new Logout());
    }
}
