import { HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, LoggerService } from '@phx/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BalanceAccounts } from './balance-account';
import { KpisMonthly } from './kpis';
import { Transactions } from './transaction';

@Injectable({
    providedIn: 'root'
})
export class MerchantService {
    constructor(private api: ApiService, private logger: LoggerService) {}

    getKpisMonthly(startMonth?: string, endMonth?: string): Observable<KpisMonthly> {
        return this.api.get<KpisMonthly>('merchants/kpis/monthly', { startMonth: startMonth, endMonth: endMonth }).pipe(
            tap({
                next: response => this.logger.debug('[MERCHANT-SERVICE] get KPIs successful', ['response', response]),
                error: error => this.logger.debug('[MERCHANT-SERVICE] get KPIs failed', ['error', error])
            })
        );
    }

    getBalanceAccounts() {
        return this.api.get<BalanceAccounts>('merchants/payments/adyen/balanceAccounts').pipe(
            tap({
                next: response =>
                    this.logger.debug('[MERCHANT-SERVICE] get balance accounts successful', ['response', response]),
                error: error => this.logger.debug('[MERCHANT-SERVICE] get balance accounts failed', ['error', error])
            })
        );
    }

    getTransactions(
        limit: number,
        createdSince: string,
        createdUntil?: string,
        cursor?: string,
        balanceAccountId?: string
    ) {
        const params = new HttpParams({
            encoder: new HttpQueryParamPlusSignCodec(),
            fromObject: {
                limit,
                createdSince,
                ...(createdUntil && { createdUntil }),
                ...(cursor && { cursor }),
                ...(balanceAccountId && { balanceAccountId })
            }
        });

        return this.api.get<Transactions>('merchants/payments/adyen/transactions?' + params.toString()).pipe(
            tap({
                next: response =>
                    this.logger.debug('[MERCHANT-SERVICE] get transactions successful', ['response', response]),
                error: error => this.logger.debug('[MERCHANT-SERVICE] get transactions failed', ['error', error])
            })
        );
    }
}

// used to encode "+" as "%2B" for ISO date query parameters
export class HttpQueryParamPlusSignCodec extends HttpUrlEncodingCodec {
    override encodeValue(value: string) {
        return super.encodeValue(value).replace(/\+/g, '%2B');
    }
}
