import { isBrowser } from './browser-detection';

export function getCookie(name: string) {
    try {
        const match = isBrowser && document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) {
            return match[2];
        }
    } catch (e) {}
}
