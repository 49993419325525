import { Inject, Injectable } from '@angular/core';
import { ServerVarsService } from '../server-vars/server-vars.service';
import { Config, LOCAL_CONFIG, LOCAL_CONFIG_OVERRIDES } from './configuration-types';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    private config: Config;

    isLoaded = false;

    constructor(
        private serverVars: ServerVarsService,
        @Inject(LOCAL_CONFIG_OVERRIDES) private localConfigOverrides: Config,
        @Inject(LOCAL_CONFIG) private localConfig: Config
    ) {
        const startupConfigFromServerRaw = this.serverVars.get('CONFIG') || '{}';
        let startupConfigFromServer: any;
        try {
            startupConfigFromServer = JSON.parse(startupConfigFromServerRaw);
        } catch (exc) {
            console.error('[CONFIG] Cannot parse startup config value:', exc);
            throw exc;
        }

        // Note: on dockerized production builds on other environments then 'local', local config and overrides are emptied
        // and so will not override any cloud config value
        this.config = Object.assign({}, this.localConfig, startupConfigFromServer, this.localConfigOverrides);

        // If config is empty, probably something went wrong
        this.isLoaded = Object.keys(this.config).length > 0;
    }

    get<T = any>(key: string, isOptional = false) {
        this.checkIfLoaded();

        if (!isOptional && !this.config.hasOwnProperty(key)) {
            throw new Error(`[CONFIG] The config object has no property with the requested key '${key}'`);
        }

        return this.config[key] as T;
    }

    getConfig(): Config {
        this.checkIfLoaded();

        return this.config;
    }

    private checkIfLoaded() {
        if (!this.isLoaded) {
            throw new Error(`[CONFIG] The config object is empty - not loaded correctly`);
        }
    }
}
