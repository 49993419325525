import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { PlatformService } from '../universal/platform.service';
import { BREAKPOINTS } from './breakpoint-config';

export interface Breakpoint {
    alias: string;
    query: string;
    matches: boolean;
    isMain: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class BreakpointService {
    activeBreakpoint$: Observable<string> = of('all');
    allActiveQueries$: Observable<Breakpoint[]> = of([]);

    private allQueries: Breakpoint[] = [];
    private allMatchMediaChanges$ = new BehaviorSubject<Breakpoint>(null);
    private useSafariAddListener = false;

    constructor(private ngZone: NgZone, private platform: PlatformService) {}

    init() {
        if (this.platform.isServer) {
            return;
        }

        // Safari < 14 only support the now deprecated 'addListener' method on MediaQueryList
        if (typeof window.matchMedia('').addEventListener === 'undefined') {
            this.useSafariAddListener = true;
        }

        // initialize with currently active media queries
        for (const [alias, [query, isMainBreakpoint]] of Object.entries(BREAKPOINTS)) {
            const isCurrentlyMatching = window.matchMedia(query).matches;
            const breakpoint = { alias, query, matches: isCurrentlyMatching, isMain: isMainBreakpoint } as Breakpoint;

            this.allQueries.push(breakpoint);

            // setup listener for future changes
            this.setupMatchMediaListener(query);

            // emit initial breakpoint state if active
            if (isCurrentlyMatching) {
                this.allMatchMediaChanges$.next(breakpoint);
            }
        }

        // setup observables for consumers
        this.allActiveQueries$ = this.allMatchMediaChanges$.pipe(
            debounceTime(0),
            switchMap(() => of(this.allQueries.filter(breakpoint => breakpoint.matches))),
            shareReplay(1)
        );

        // One of the main, mutually exclusive breakpoints (e.g. xs, sm, md, lg, xl)
        this.activeBreakpoint$ = this.allActiveQueries$.pipe(
            map(allActiveQueries => allActiveQueries.find(breakpoint => breakpoint.isMain).alias),
            distinctUntilChanged()
        );
    }

    isActive(alias: string): boolean {
        return this.allQueries.some(bp => bp.alias === alias && bp.matches);
    }

    isActive$(alias: string): Observable<boolean> {
        return this.allActiveQueries$.pipe(
            map(allActiveQueries => allActiveQueries.some(breakpoint => breakpoint.alias === alias)),
            distinctUntilChanged()
        );
    }

    private setupMatchMediaListener(query) {
        // subscribe to events for all given media queries
        const listenerFn = (mqlEvent: MediaQueryListEvent) => {
            const changedBreakpoint = this.allQueries.find(breakpoint => breakpoint.query === query);
            changedBreakpoint.matches = mqlEvent.matches;

            this.ngZone.run(() => this.allMatchMediaChanges$.next(changedBreakpoint));
        };

        if (this.useSafariAddListener) {
            window.matchMedia(query).addListener(listenerFn);
        } else {
            window.matchMedia(query).addEventListener('change', listenerFn);
        }
    }
}
