import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { PhxRouteReuseStrategy } from '@phx/core';
import { AdyenOnboardingComponent } from './adyen-onboarding/adyen-onboarding.component';
import { LoginComponent } from './auth/components/login/login.component';
import { AuthLoadedGuard } from './auth/services/auth-loaded.guard';
import { EmptyComponent } from './empty/empty.component';
import { KpisComponent } from './kpis/kpis.component';
import { TransactionsComponent } from './transactions/transactions.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent, data: { title: 'Login' } },
    {
        path: 'kpis',
        component: KpisComponent,
        canActivate: mapToCanActivate([AuthLoadedGuard]),
        data: { title: 'KPIs' }
    },
    {
        path: 'paymentMethods',
        component: AdyenOnboardingComponent,
        canActivate: mapToCanActivate([AuthLoadedGuard]),
        data: { title: 'Bezahlmethoden' }
    },
    {
        path: 'transactions',
        component: TransactionsComponent,
        canActivate: mapToCanActivate([AuthLoadedGuard]),
        data: { title: 'Kontoübersicht & Transaktionen' }
    },
    {
        path: '**',
        component: EmptyComponent,
        canActivate: mapToCanActivate([AuthLoadedGuard]),
        data: { title: 'Merchant Cockpit' }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: PhxRouteReuseStrategy
        }
    ]
})
export class AppRoutingModule {}
