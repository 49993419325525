<div class="container p-3">
    <h1>{{ resources['mc.transactions.title'] }}</h1>

    <ng-template #introComponentPlaceholder phxDynamicComponent></ng-template>

    <ngb-alert ngb-alert type="info" [dismissible]="false" class="mt-5" [class.d-none]="isOnboardedOrLoading">
        <ng-template #notOnboardedInfoComponentPlaceholder phxDynamicComponent></ng-template>
    </ngb-alert>

    <div [class.d-none]="!isOnboardedOrLoading">
        <h2 class="mt-5">{{ resources['mc.transactions.balanceAccounts.title'] }}</h2>

        <ng-template #balanceAccountsIntroComponentPlaceholder phxDynamicComponent></ng-template>

        <div class="overflow-auto">
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th scope="col">{{ resources['mc.transactions.balanceAccounts.table.id.label'] }}</th>
                        <th scope="col">{{ resources['mc.transactions.balanceAccounts.table.status.label'] }}</th>
                        <th scope="col">{{ resources['mc.transactions.balanceAccounts.table.balance.label'] }}</th>
                        <th scope="col">{{ resources['mc.transactions.balanceAccounts.table.available.label'] }}</th>
                        <th scope="col">{{ resources['mc.transactions.balanceAccounts.table.reserved.label'] }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let account of balanceAccounts">
                        <th>
                            <strong>{{ account.id }}</strong>
                        </th>
                        <td>{{ account.status }}</td>
                        <td>{{ account.balances[0]?.balance | currency : 'EUR' }}</td>
                        <td>{{ account.balances[0]?.balance | currency : 'EUR' }}</td>
                        <td>{{ account.balances[0]?.available | currency : 'EUR' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="balanceAccountsLoading" class="general-spinner my-4 text-center">
            <div class="spinner-border text-danger" role="status"></div>
        </div>

        <ng-template #balanceAccountsLegendComponentPlaceholder phxDynamicComponent></ng-template>

        <h2 class="mt-5">{{ resources['mc.transactions.transactionListing.title'] }}</h2>

        <ng-template #transactionListingIntroComponentPlaceholder phxDynamicComponent></ng-template>

        <div class="d-flex py-3 align-items-end">
            <phx-range-datepicker
                [initialFromDate]="transactionCreatedSince"
                [initialToDate]="transactionCreatedUntil"
                (dateChange)="onTransactionDateChange($event)"
            ></phx-range-datepicker>

            <div ngbDropdown class="ms-3">
                max Zeilen:
                <div>
                    <button type="button" class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>
                        {{ transactionLimit }}
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button
                            *ngFor="let limit of transactionLimits"
                            ngbDropdownItem
                            (click)="onTransactionLimitChange(limit)"
                        >
                            {{ limit }}
                        </button>
                    </div>
                </div>
            </div>

            <button
                type="submit"
                class="btn btn-outline-primary ms-3"
                [disabled]="!areTransactionDatesValid"
                (click)="submitTransactionParams()"
            >
                Transaktionen laden
            </button>

            <div class="ms-auto">
                <ng-container *ngTemplateOutlet="pagination"></ng-container>
            </div>
        </div>

        <div *ngIf="!transactionsLoading && !transactionsError" class="overflow-auto">
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th scope="col" phxSortable="createdAt" (phxSort)="onTransactionSort($event)">
                            {{ resources['mc.transactions.transactionListing.table.createdAt.label'] }}
                        </th>
                        <th scope="col" phxSortable="id" (phxSort)="onTransactionSort($event)">
                            {{ resources['mc.transactions.transactionListing.table.id.label'] }}
                        </th>
                        <th scope="col" phxSortable="merchantOrderCode" (phxSort)="onTransactionSort($event)">
                            {{ resources['mc.transactions.transactionListing.table.merchantOrderCode.label'] }}
                        </th>
                        <th scope="col" phxSortable="amount" (phxSort)="onTransactionSort($event)">
                            {{ resources['mc.transactions.transactionListing.table.amount.label'] }}
                        </th>
                        <th scope="col" phxSortable="type" (phxSort)="onTransactionSort($event)">
                            {{ resources['mc.transactions.transactionListing.table.type.label'] }}
                        </th>
                        <th scope="col" phxSortable="bookingDate" (phxSort)="onTransactionSort($event)">
                            {{ resources['mc.transactions.transactionListing.table.bookingDate.label'] }}
                        </th>
                        <th scope="col" phxSortable="valueDate" (phxSort)="onTransactionSort($event)">
                            {{ resources['mc.transactions.transactionListing.table.valueDate.label'] }}
                        </th>
                        <th scope="col" phxSortable="description" (phxSort)="onTransactionSort($event)">
                            {{ resources['mc.transactions.transactionListing.table.description.label'] }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transaction of sortedTransactions">
                        <td>{{ transaction.createdAt | date : 'dd.MM.YYYY HH:mm' }}</td>
                        <td>
                            <a
                                [routerLink]="['/transactions']"
                                [queryParams]="{ id: transaction.id }"
                                [queryParamsHandling]="'merge'"
                                (click)="goToSelectedTransaction(transaction.id)"
                                >{{ transaction.id }}</a
                            >
                        </td>
                        <td>
                            <a
                                *ngIf="transaction.merchantOrderCode"
                                [href]="getMppOrderUrl(transaction.merchantOrderCode)"
                                target="_blank"
                                >{{ transaction.merchantOrderCode | uppercase }}</a
                            >
                        </td>
                        <td>{{ transaction.amount | currency : 'EUR' }}</td>
                        <td>{{ transaction.type }}</td>
                        <td>{{ transaction.bookingDate | date : 'dd.MM.YYYY HH:mm' }}</td>
                        <td>{{ transaction.valueDate | date : 'dd.MM.YYYY HH:mm' }}</td>
                        <td class="word-wrap">{{ transaction.description }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="transactionsLoading" class="general-spinner my-4 text-center">
            <div class="spinner-border text-danger" role="status"></div>
        </div>

        <div *ngIf="!transactionsLoading && transactionsError" class="text-center">
            <ngb-alert type="danger" [dismissible]="false">
                {{ transactionsLoadingErrorText }}
            </ngb-alert>
        </div>

        <div *ngIf="!transactionsLoading && !transactionsError && !transactions?.length" class="text-center">
            <ngb-alert type="info" [dismissible]="false">
                {{ resources['mc.transactions.transactionListing.noTransactionsFound.info'] }}
            </ngb-alert>
        </div>

        <div class="d-flex justify-content-end">
            <ng-container *ngTemplateOutlet="pagination"></ng-container>
        </div>

        <ng-template #transactionListingLegendComponentPlaceholder phxDynamicComponent></ng-template>

        <ng-container *ngIf="selectedTransaction">
            <h2 #transactionDetails class="mt-5">
                {{ resources['mc.transactions.transactionDetails.title'] }} {{ selectedTransaction.id }}
            </h2>

            <div class="mt-3 overflow-auto">
                <table class="table table-striped table-bordered table-hover mb-5">
                    <tbody>
                        <tr *ngFor="let entry of selectedTransactionEntries">
                            <th>{{ entry.key }}</th>
                            <td>{{ entry.value }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #pagination>
    <div *ngIf="!transactionsLoading" class="input-group w-auto">
        <button
            type="button"
            [disabled]="!prevTransactionPageCursor"
            class="btn btn-outline-secondary px-5 bi-chevron-left"
            (click)="goToPrevTransactionPage()"
        ></button>

        <button
            type="button"
            [disabled]="!nextTransactionPageCursor"
            class="btn btn-outline-secondary px-5 bi-chevron-right"
            (click)="goToNextTransactionPage()"
        ></button>
    </div>
</ng-template>
