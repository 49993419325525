import { Component } from '@angular/core';
import { BaseContentItemComponent, LoggerService } from '@phx/core';

@Component({
    selector: 'phx-fallback',
    templateUrl: './fallback.component.html',
    styleUrls: ['./fallback.component.scss']
})
export class FallbackComponent extends BaseContentItemComponent {
    constructor(protected override logger: LoggerService) {
        super(logger);
    }
}
