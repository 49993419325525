import { createNgModule, Injectable, Injector } from '@angular/core';
import { DynamicComponentDirective } from '@phx/core-exports';
import { noop } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ComponentContext } from '../content/component-context';
import { ContentItem } from '../content/content-item';
import { LoggerService } from '../logging/logger.service';
import { ServerVarsService } from '../server-vars/server-vars.service';
import { PlatformService } from '../universal/platform.service';
import { isContentItem } from '../utils/is-content-item';
import { ContentItemTypeMapService } from './content-item-type-map.service';
import { LazyModuleHelper } from './lazy-module-helper.service';
import { RestrictionCheckService } from './restriction-check.service';

@Injectable({
    providedIn: 'root'
})
export class DynamicComponentService {
    constructor(
        private contentItemTypeMapService: ContentItemTypeMapService,
        private restrictionCheck: RestrictionCheckService,
        private injector: Injector,
        private logger: LoggerService,
        private lazyModuleHelper: LazyModuleHelper,
        private serverVars: ServerVarsService,
        private platform: PlatformService
    ) {}

    clearComponents(placeholderElement: DynamicComponentDirective) {
        this.checkPlaceholderElement(placeholderElement);

        // clear the placeholder of old components first
        placeholderElement.viewContainerRef.clear();
    }

    renderComponent(
        contentItem: ContentItem,
        placeholderElement: DynamicComponentDirective,
        componentContext?: ComponentContext,
        parent: ContentItem = null,
        skipContainer = false
    ) {
        if (!contentItem) {
            this.logger.error(
                new Error(
                    `[DYNAMIC COMPONENT] ContentItem cannot be null` +
                        (parent ? ` (Parent: ${parent.metadata?.id}, "${parent.metadata?.name}")` : '')
                )
            );
            return;
        }

        if (!isContentItem(contentItem)) {
            this.logger.error(new Error(`[DYNAMIC COMPONENT] Not a valid ContentItem`));
            return;
        }

        // on each component there will be an array of all its parents in the data.parents property
        // unless the chain is broken, when the direct rendering parent is not passed on to renderComponent()
        if (parent) {
            // stripping `fields` as we don't need them here and they are causing issues (TypeError Converting circular structure to JSON) in TransferState for SSR
            const parents = [...(parent.data?.parents || []), { ...parent, fields: {} }] as ContentItem[];
            contentItem.data = { ...contentItem.data, parents };
        }

        this.checkPlaceholderElement(placeholderElement);

        // do we need to deal with restrictions and wrap the component in a container
        const nextContainer = this.getRestrictionContainer(contentItem);
        const useContainer = !!nextContainer && !skipContainer;

        // get component type to render from the ContentItem
        const componentTypeString = this.getComponentType(contentItem);

        // Find the componentClass of the desired ContentItem type
        const componentTypeStringToUse = useContainer ? nextContainer : componentTypeString;
        let componentInfo = this.contentItemTypeMapService.getMap().get(componentTypeStringToUse);

        if (!componentInfo) {
            // component not known (component configured in CMS but the module that contains the component was not loaded yet).
            // try to find it in lazy-load ed modules
            const lazyLoadedModuleImporter = this.lazyModuleHelper.getLazyModuleImporter(componentTypeStringToUse);

            if (lazyLoadedModuleImporter) {
                // it is a lazy-loaded component but is not yet known, so load it and postpone processing
                this.loadModuleForComponent(componentTypeStringToUse)
                    .then(() => {
                        // try this thing again
                        this.renderComponent(contentItem, placeholderElement, componentContext, parent, skipContainer);
                    })
                    .catch(() => {
                        // just don't render it
                        this.logger.warn(
                            `[DYNAMIC COMPONENT] Error while lazy-loading module for component '${componentTypeStringToUse}'. Skipping the component.`
                        );
                    });
                return;
            }

            // component also not found in lazy-loaded modules
            this.logger.warn(
                `[DYNAMIC COMPONENT] Can't find client component for CMS type '${componentTypeString}' (id: '${contentItem.metadata.id}').`
            );
            if (this.serverVars.getEnvironment() === 'prod') {
                return;
            }
            // fallback for non-prod build:
            componentInfo = this.contentItemTypeMapService.getMap().get('__fallback__');
        }

        // actual render:
        // get component class to render
        const componentClass = componentInfo.component;
        // get ngModule the given component is part of (needed for dynamic creation of components outside of root module)
        const componentNgModule = componentInfo.ngModule;

        // get content to inject into the projectable nodes (ng-content) of the component if needed
        const componentProjectableNodes = this.getProjectableNodesForComponent(componentTypeStringToUse, contentItem);

        // Create the component, attach it to the viewContainer and bind the data
        const componentRef = placeholderElement.viewContainerRef.createComponent(componentClass, {
            projectableNodes: componentProjectableNodes,
            environmentInjector: componentNgModule
        });

        // add the component's own contentItem to it
        componentRef.instance['contentItem'] = contentItem;

        // add additional data if available
        componentRef.instance['componentContext'] = componentContext;
    }

    renderComponentList(
        contentItems: ContentItem[],
        placeholderElement: DynamicComponentDirective,
        componentContext?: ComponentContext,
        parent?: ContentItem,
        stopAfterFirstValid = false,
        ignoreMissingComponents = false
    ) {
        if (!contentItems || contentItems.length === 0) {
            return;
        }

        if (!isContentItem(contentItems[0])) {
            this.logger.error(new Error(`[DYNAMIC COMPONENT] Not a list of valid ContentItems`));
            return;
        }

        this.checkPlaceholderElement(placeholderElement);
        this.clearComponents(placeholderElement);

        // check if any component is in a lazy-loaded module and not yet loaded
        // to assure the correct rendering order of components in the given list
        if (!ignoreMissingComponents) {
            const loadModulePromises = [];
            for (const ci of contentItems) {
                const componentTypeString = this.getComponentType(ci);
                // check if component is lazy-loaded and if it needs to be loaded (not in map)
                if (
                    this.lazyModuleHelper.getLazyModuleImporter(componentTypeString) &&
                    !this.contentItemTypeMapService.getMap().get(componentTypeString)
                ) {
                    // component is lazy-loaded and not yet available, so load it and postpone processing
                    loadModulePromises.push(this.loadModuleForComponent(componentTypeString));
                }
            }

            if (loadModulePromises.length > 0) {
                // wait for all promises and ignore errors
                Promise.all(loadModulePromises.map(p => p.catch(noop))).then(() => {
                    // all loaded. try this thing again
                    this.renderComponentList(
                        contentItems,
                        placeholderElement,
                        componentContext,
                        parent,
                        stopAfterFirstValid,
                        true
                    );
                });
                // postpone processing until all needed modules are loaded
                return;
            }
        }

        // all should be available, so render them
        for (const contentItem of contentItems) {
            const checkResult = this.restrictionCheck.check(contentItem);
            if (checkResult.isValid) {
                this.renderComponent(contentItem, placeholderElement, componentContext, parent);

                if (stopAfterFirstValid && !checkResult.isDynamicallyEvaluated) {
                    return;
                }
            }
        }
    }

    private checkPlaceholderElement(placeholderElement: DynamicComponentDirective) {
        if (!placeholderElement && !environment.production) {
            const msg = `'placeholderElement' is null == CMS content has no element to be attached to. Make sure you have:
            1) imported SharedModule to module where your CMS component is declared
            2) you have defined <ng-template #layoutPlaceholder phxDynamicComponent></ng-template> in your CMS component's template
            3) you have such property in your CMS component:
                @ViewChild('layoutPlaceholder', { read: DynamicComponentDirective })
                layoutPlaceholder: DynamicComponentDirective;
            `;
            throw new Error(msg);
        }
    }

    private async loadModuleForComponent(componentTypeString: string) {
        try {
            // get module importer for given component
            const moduleImporter = this.lazyModuleHelper.getLazyModuleImporter(componentTypeString);

            // load given module
            const moduleType = await moduleImporter.loadModule();

            createNgModule(moduleType, this.injector);

            // check if component is now known (was part of the given module)
            if (!this.contentItemTypeMapService.getMap().get(componentTypeString)) {
                // not known, wrong configuration in component/module map
                throw new Error(
                    `Component '${componentTypeString}' still not available after loading module '${moduleType.toString()}'. Check lazy-loading configuration`
                );
            }
        } catch (e) {
            this.logger.error(e);
            throw e;
        }
    }

    private getRestrictionContainer(contentItem: ContentItem) {
        const { restrictions } = contentItem.metadata;
        const anyRestrictions = restrictions && Object.values(restrictions).some(v => v);

        return anyRestrictions && 'DynamicComponentRestrictionContainerComponent';
    }

    private getComponentType(contentItem: ContentItem) {
        // if it is a feature component, get the actual component configured to render
        if (contentItem.metadata.type === 'featureComponent') {
            return contentItem.fields['componentName'] as string;
        }

        return contentItem.metadata.type;
    }

    private getProjectableNodesForComponent(componentName: string, contentItem: ContentItem) {
        if (componentName === 'componentLink') {
            const linkText = this.platform.document.createTextNode(contentItem?.fields?.['__contentLink']?.['title']);
            return [[linkText]];
        }

        return undefined;
    }
}
