import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { LoggerService } from '../logging/logger.service';
import { convertParamsToRequestPath, handleHttpError } from './api-service-utils';
import { ApiService } from './api.service';

const mockedApiResponses = {};

@Injectable({
    providedIn: 'root'
})
export class MockedApiService implements ApiService {
    constructor(private urlSerializer: UrlSerializer, private logger: LoggerService) {}
    public put<T>(
        requestPath: string,
        params?: HttpParams | { [id: string]: any },
        body?: { [id: string]: any },
        options?: Record<string, unknown>
    ): Observable<T> {
        throw new Error('Method not implemented.');
    }

    public delete<T>(
        requestPath: string,
        params?: HttpParams | { [id: string]: any },
        options?: Record<string, unknown>
    ): Observable<T> {
        throw new Error('Method not implemented.');
    }

    get<T>(
        requestPath: string,
        params?: { [id: string]: any } | HttpParams,
        options?: Record<string, unknown>
    ): Observable<T> {
        requestPath = convertParamsToRequestPath(requestPath, params);

        this.logger.debug(`*** MOCKED API CALL: ${requestPath}`);

        let foundMockedResponse = mockedApiResponses['/' + requestPath];
        if (foundMockedResponse == null) {
            const urlTree = this.urlSerializer.parse(requestPath);
            if (urlTree.queryParamMap.has('id')) {
                if (urlTree.queryParams['id'].startsWith('/p/')) {
                    urlTree.queryParams['id'] = '/p/';
                } else if (urlTree.queryParams['id'].startsWith('/c/')) {
                    urlTree.queryParams['id'] = '/c/';
                } else {
                    urlTree.queryParams['id'] = '';
                }

                // try again to get the fallback response
                foundMockedResponse = mockedApiResponses[urlTree.toString().replace(/%2F/g, '/')];
            }
        }

        if (foundMockedResponse == null) {
            this.logger.debug(`*** MOCKED API: requestPath '/${requestPath}' not found`);
            return EMPTY;
        }

        if (foundMockedResponse.error) {
            return handleHttpError(foundMockedResponse, { urlWithParams: requestPath } as HttpRequest<any>);
        }

        return of(foundMockedResponse.data);
    }

    post<T>(
        requestPath: string,
        params?: { [id: string]: any } | HttpParams,
        payload?: { [id: string]: any },
        options?: Record<string, unknown>
    ): Observable<T> {
        requestPath = convertParamsToRequestPath(requestPath, payload);

        this.logger.debug(`*** MOCKED API CALL: ${requestPath}`);

        let foundMockedResponse = mockedApiResponses['/' + requestPath];
        if (foundMockedResponse == null) {
            const urlTree = this.urlSerializer.parse(requestPath);
            if (urlTree.queryParamMap.has('id')) {
                if (urlTree.queryParams['id'].startsWith('/p/')) {
                    urlTree.queryParams['id'] = '/p/';
                } else if (urlTree.queryParams['id'].startsWith('/c/')) {
                    urlTree.queryParams['id'] = '/c/';
                } else {
                    urlTree.queryParams['id'] = '';
                }

                // try again to get the fallback response
                foundMockedResponse = mockedApiResponses[urlTree.toString().replace(/%2F/g, '/')];
            }
        }

        if (foundMockedResponse == null) {
            this.logger.debug(`*** MOCKED API: requestPath '/${requestPath}' not found`);
            return EMPTY;
        }

        if (foundMockedResponse.error) {
            return handleHttpError(foundMockedResponse, { urlWithParams: requestPath } as HttpRequest<any>);
        }

        return of(foundMockedResponse.data);
    }

    download(requestPath: string): Observable<Blob> {
        throw new Error('Method not implemented.');
    }
}
