import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NavigationService, PREVENT_ROUTE_REFRESH } from '@phx/core';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import {
    AuthActionTypes,
    AutoLogin,
    AutoLoginCancelled,
    AutoLoginFailed,
    AutoLoginSucceeded,
    Impersonate,
    ImpersonateFailed,
    ImpersonateSucceeded,
    Login,
    LoginFailed,
    LoginSucceeded,
    Logout,
    LogoutFailed,
    LogoutSucceeded,
    SkipImpersonation
} from './auth.actions';
import { getAuthState, getIsImpersonatingAllowed, getReturnUrl } from './auth.selectors';

const MERCHANT_UID_QUERYPARAM_KEY = 'merchantUid';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private navigationService: NavigationService,
        private store: Store,
        @Inject(DOCUMENT) private document: Document
    ) {}

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType<Login>(AuthActionTypes.LOGIN),
            exhaustMap(action =>
                this.authService.login(action.credentials).pipe(
                    map(user => {
                        return new LoginSucceeded({ user, withMigration: false });
                    }),
                    catchError(error => {
                        if (error.code === 401) {
                            this.router.navigate(['/']);
                        }

                        return of(new LoginFailed(error));
                    })
                )
            )
        )
    );

    loginSucceded$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<LoginSucceeded>(AuthActionTypes.LOGIN_SUCCEEDED),
                concatLatestFrom(() => this.store.select(getReturnUrl)),
                tap(([_, returnUrl]) => {
                    const refreshForbidden = !!this.navigationService.routerStateSnapshot.data?.[PREVENT_ROUTE_REFRESH];

                    if (returnUrl) {
                        this.router.navigateByUrl(returnUrl, { replaceUrl: true });
                    } else if (!refreshForbidden) {
                        this.navigationService.refreshCurrentRoute();
                    }
                    // TODO: call a cleanup login action?
                })
            ),
        { dispatch: false }
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType<Logout>(AuthActionTypes.LOGOUT),
            exhaustMap(action =>
                this.authService.logout().pipe(
                    map(() => new LogoutSucceeded(action.targetPath)),
                    catchError(error => of(new LogoutFailed(error)))
                )
            )
        )
    );

    logoutHappened$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<LogoutSucceeded | LoginFailed>(AuthActionTypes.LOGOUT_SUCCEEDED, AuthActionTypes.LOGOUT_FAILED),
                tap(action => {
                    // force reload
                    if (action instanceof LogoutSucceeded) {
                        this.document.location.href = action.targetPath || '/';
                    } else {
                        this.document.location.href = '/';
                    }
                })
            ),
        { dispatch: false }
    );

    autoLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType<AutoLogin>(AuthActionTypes.AUTOLOGIN),
            concatLatestFrom(() => this.store.select(getAuthState)),
            exhaustMap(([autoLogin, authState]) => {
                if (!authState.loaded || autoLogin.payload) {
                    return this.authService.autoLogin().pipe(
                        map(
                            apiResponse =>
                                new AutoLoginSucceeded({
                                    user: apiResponse.data,
                                    isSemiAuth:
                                        apiResponse.headers?.get('x-phx-authentication-state') === 'SEMI_AUTHENTICATED'
                                })
                        ),
                        catchError(error => of(new AutoLoginFailed(error)))
                    );
                } else {
                    return of(new AutoLoginCancelled());
                }
            })
        )
    );

    impersonateAfterLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActionTypes.AUTOLOGIN_SUCCEEDED, AuthActionTypes.LOGIN_SUCCEEDED),
            concatLatestFrom(() => this.store.select(getIsImpersonatingAllowed)),
            exhaustMap(([_, isImpersonatingAllowed]) => {
                const uidToImpersonate = new URL(this.document.location.toString()).searchParams.get(
                    MERCHANT_UID_QUERYPARAM_KEY
                );

                if (!uidToImpersonate || !isImpersonatingAllowed) {
                    return of(new SkipImpersonation());
                }

                return of(new Impersonate(uidToImpersonate));
            })
        )
    );

    impersonate$ = createEffect(() =>
        this.actions$.pipe(
            ofType<Impersonate>(AuthActionTypes.IMPERSONATE),
            exhaustMap(action =>
                this.authService.impersonate(action.impersonatedUserId).pipe(
                    map(user => {
                        return new ImpersonateSucceeded({ user });
                    }),
                    catchError(error => of(new ImpersonateFailed(error)))
                )
            )
        )
    );
}
