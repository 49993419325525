import { Injectable } from '@angular/core';
import { PlatformService } from '../universal/platform.service';

@Injectable({ providedIn: 'root' })
export class CustomCssService {
    cssRegex = RegExp(`([^{]+?)\s*(\{\s*[^}]*?\s*})`, 'g');
    cmsIdClassCache = new Set<string>();

    constructor(private platform: PlatformService) {}

    injectCss(customCssString: string, cmsIdClass?: string) {
        if (!customCssString) {
            return;
        }

        if (!cmsIdClass) {
            // do not scope to specific CMS component. just insert the custom CSS
            this.addStyleElement(customCssString);
            return;
        }

        if (this.cmsIdClassCache.has(cmsIdClass)) {
            // already added
            return;
        }

        // parse CSS rules
        let finalCssString = '';
        let cssRuleMatches = this.cssRegex.exec(customCssString);

        while (cssRuleMatches !== null) {
            // scope styles to current cms component
            const customizedSelectorsForRule = cssRuleMatches[1]
                .split(',')
                .map(c =>
                    c.trim().startsWith(':root ') || c.trim().startsWith('html ')
                        ? c.trim()
                        : `.phx-body .${cmsIdClass} ${c.trim() !== ':host' ? c.trim() : ''}`
                )
                .join(',\n');

            finalCssString += `${customizedSelectorsForRule} ${cssRuleMatches[2]}\n`;
            cssRuleMatches = this.cssRegex.exec(customCssString);
        }

        this.addStyleElement(finalCssString, cmsIdClass);
        this.cmsIdClassCache.add(cmsIdClass);
    }

    addStyleElement(css: string, cmsIdClass?: string) {
        const headElement = this.platform.document.querySelector('head');
        const newStyleElement = this.platform.document.createElement('style');
        if (cmsIdClass) {
            newStyleElement.setAttribute('data-cms-id', cmsIdClass);
        }

        headElement.appendChild(newStyleElement);
        if (newStyleElement['styleSheet']) {
            // IE
            newStyleElement['styleSheet'].cssText = css;
        } else {
            // the world
            newStyleElement.appendChild(this.platform.document.createTextNode(css));
        }
    }

    removeCustomStyleElement(cmsIdClass: string) {
        if (!cmsIdClass) {
            return;
        }
        const customStyleBlock = this.platform.document.querySelector(`[data-cms-id='${cmsIdClass}']`);
        if (customStyleBlock) {
            customStyleBlock.remove();
            this.cmsIdClassCache.delete(cmsIdClass);
        }
    }
}
