import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../logging';
import { ServerVarsService } from '../server-vars/server-vars.service';
import { PlatformService } from './platform.service';

@Injectable()
export class ServerSideRelativeUrlHttpInterceptor implements HttpInterceptor {
    constructor(
        private platform: PlatformService,
        private serverVars: ServerVarsService,
        private logger: LoggerService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const dockerHost =
            this.serverVars.get('DOCKER_LOCAL_WEBSERVER_HOST') || environment['dockerLocalWebserverHost'];

        // Don't need to care about absolute urls
        if (req.url.startsWith('http')) {
            //if (req.url.startsWith('http') || req.url.startsWith('//')) {
            return next.handle(req);
        }

        if (this.platform.isServer) {
            // handle relative urls on node server
            if (req.url.startsWith('./')) {
                const newUrl = `${dockerHost}/${req.url.replace('./', '')}`;

                if (req.url !== './log') {
                    // avoid endless loops to log endpoint
                    this.logger.debug(`[SSR] Rewrote local url to: ${newUrl}`);
                }

                return next.handle(
                    req.clone({
                        url: newUrl
                    })
                );
            }
        }

        return next.handle(req);
    }
}
