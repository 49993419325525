import { AfterViewInit, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import {
    BaseContentItemComponent,
    CustomCssService,
    CustomScriptService,
    LoggerService,
    NavigationService
} from '@phx/core';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'phx-raw-html',
    templateUrl: './raw-html.component.html',
    styleUrls: ['./raw-html.component.scss']
})
export class RawHtmlComponent extends BaseContentItemComponent implements OnInit, OnDestroy, AfterViewInit {
    @HostBinding('class.global-injection')
    isGlobalInjection = false;

    constructor(
        protected override logger: LoggerService,
        protected override customCssService: CustomCssService,
        private customScriptService: CustomScriptService,
        private navigationService: NavigationService
    ) {
        super(logger, customCssService);
    }

    override ngOnInit() {
        super.ngOnInit();

        this.isGlobalInjection = this.componentContext?.isGlobalInjection;
    }

    override ngOnDestroy() {
        if (this.contentItem?.metadata?.id) {
            this.customScriptService.removeScript(this.contentItem.metadata.id);
        }

        super.ngOnDestroy();
    }

    ngAfterViewInit() {
        if (!this.contentItem?.metadata?.id) {
            return;
        }

        setTimeout(() => {
            this.injectScript();
        }, 0);
    }

    private injectScript() {
        this.navigationService.isStable$
            .pipe(
                filter(
                    isStable =>
                        // do not wait for stable if disabled in CMS component
                        this.contentItem.fields?.['customScriptAfterStable'] === false || isStable
                ),
                take(1)
            )
            .subscribe(() => {
                if (this.contentItem.fields['customScript'] && this.customScriptService) {
                    this.customScriptService.injectScript(
                        this.contentItem.fields['customScript'].toString(),
                        this.contentItem.metadata.id
                    );
                }
            });
    }
}
