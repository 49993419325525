import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImageFormatSupportService {
    isAvifSupported = false;
    isWebpSupported = false;

    init() {
        try {
            const image = new Image();
            image.src = `data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=`;
            image.onload = () => {
                this.isAvifSupported = true;
            };
            image.onerror = () => {
                this.isAvifSupported = false;
            };

            // webp
            this.isWebpSupported =
                document.createElement('canvas').toDataURL(`image/webp`).indexOf(`data:image/webp`) === 0;
        } catch (e) {}
    }
}
