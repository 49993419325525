import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { endOfWeek, format, formatISO, isDate, parse, parseISO, startOfWeek } from 'date-fns';
import { deAT } from 'date-fns/locale';

export const formatLocalDate = (date: Date): string => {
    if (!isDate(date)) {
        return '';
    }

    return format(date, 'dd.MM.yyyy', { locale: deAT });
};

export const parseLocalDate = (value: string): Date => {
    return parse(value, 'dd.MM.yyyy', new Date(), { locale: deAT });
};

export const formatIsoDate = (date: Date): string => {
    if (!isDate(date)) {
        return '';
    }

    return formatISO(date);
};

export const parseIsoDate = (value: string): Date => {
    return parseISO(value);
};

export const getFirstDayOfWeek = (date: Date): Date => {
    return startOfWeek(date, { locale: deAT });
};

export const getLastDayOfWeek = (date: Date): Date => {
    return endOfWeek(date, { locale: deAT });
};

export const convertNgbDateToNativeDate = (date: NgbDateStruct): Date => {
    if (!date) {
        return null;
    }

    return new Date(date.year, date.month - 1, date.day, 0, 0, 0);
};

export const convertNativeDateToNgbDate = (date: Date): NgbDateStruct => {
    if (!isDate(date)) {
        return null;
    }

    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
    };
};

@Injectable()
export class NgbGermanDateAdapter extends NgbDateAdapter<Date> {
    fromModel(date: Date): NgbDateStruct {
        return convertNativeDateToNgbDate(date);
    }

    toModel(date: NgbDateStruct): Date {
        return convertNgbDateToNativeDate(date);
    }
}

@Injectable()
export class NgbGermanDateParserFormatter extends NgbDateParserFormatter {
    format(date: NgbDateStruct): string {
        return formatLocalDate(convertNgbDateToNativeDate(date));
    }

    parse(value: string): NgbDateStruct {
        return convertNativeDateToNgbDate(parseLocalDate(value));
    }
}
