import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, LastKnownAuthState } from './auth.reducer';

// reference to our lazy loaded feature module state base. (name from StoreModule.forFeature() registration at module level)
export const getAuthState = createFeatureSelector<AuthState>('auth');

// select different properties further down the state tree
export const getUser = createSelector(getAuthState, (state: AuthState) => state.user);

export const getIsLoaded = createSelector(getAuthState, (state: AuthState) => state.loaded);

export const getIsLoading = createSelector(getAuthState, (state: AuthState) => state.loading);

export const getIsImpersonationHandled = createSelector(
    getAuthState,
    state => state.loaded && state.impersonationHandled
);

export const getUserFinishedLoading = createSelector(getAuthState, state => state.loaded && !state.loading);

export const getIsLoggedIn = createSelector(
    getUser,
    getIsLoaded,
    getIsImpersonationHandled,
    (user, isLoaded, impersonationHandled) => (!isLoaded || !impersonationHandled ? undefined : !!user)
);

export const getIsFullyLoggedIn = createSelector(
    getAuthState,
    getIsLoggedIn,
    (state, isLoggedIn) => !state.isSemiAuth && isLoggedIn
);

export const getWasLoggedInBefore = createSelector(
    getAuthState,
    getIsLoggedIn,
    (state, isLoggedIn) =>
        (isLoggedIn === false && state.lastKnownAuthState === LastKnownAuthState.FullyLoggedIn) ||
        state.lastKnownAuthState === LastKnownAuthState.SemiLoggedIn
);

export const getReturnUrl = createSelector(getAuthState, (state: AuthState) => state.returnUrl);

export const getIsImpersonating = createSelector(
    getIsLoggedIn,
    getUser,
    (isLoggedIn, user) => isLoggedIn && user.uid !== user.loggedInUid
);

export const getIsImpersonatingAllowed = createSelector(getUser, user => user?.roles.includes('ROLE_ONBOARDER'));
