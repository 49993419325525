import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApiError, ConfigurationService, NavigationService } from '@phx/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InterruptLogin, InterruptLoginOnFirstRoute, SetReturnUrl } from '../store/auth.actions';

@Injectable({
    providedIn: 'root'
})
export class FullAuthNeededHttpInterceptorService implements HttpInterceptor {
    constructor(
        private store: Store,
        private router: Router,
        private navigationService: NavigationService,
        private config: ConfigurationService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap({
                error: (error: ApiError | HttpErrorResponse) => {
                    const errorCode = (error as ApiError).code || (error as HttpErrorResponse).status;

                    // TODO: we should be able to remove 403 here, as it should not happen anymore after backend is fixed
                    if (errorCode === 401 || errorCode === 402 || errorCode === 403) {
                        if (
                            request.url.endsWith('auth/logout') ||
                            request.url.endsWith('auth/autologin') ||
                            request.url.endsWith('auth/impersonate')
                        ) {
                            // ignore errors in these cases.
                            // either user is logged out anyway
                            // or, in case of a failed impersonation attempt, we let the user just fallback to the already logged in account
                            return;
                        }

                        const resolvingFirstRoute = !this.navigationService.appInitiated;

                        // change auth state
                        this.store.dispatch(
                            resolvingFirstRoute
                                ? new InterruptLoginOnFirstRoute(errorCode === 402)
                                : new InterruptLogin(errorCode === 402)
                        );

                        if ((error as ApiError)?.ignore) {
                            return;
                        }

                        if (this.config.get('showLogin')) {
                            // if login form in cockpit itself is enabled:
                            // store requested page and redirect to login page
                            this.store.dispatch(new SetReturnUrl(this.router.url));
                            this.router.navigateByUrl('/login');
                        } else {
                            // otherwise redirect to MPP login
                            this.document.location = this.config.get('mppLoginUrl');
                        }
                    }
                }
            })
        );
    }
}
