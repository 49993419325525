import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

export type SortDirection = 'asc' | 'desc' | '';

export interface SortEvent {
    column: string;
    direction: SortDirection;
}

@Directive({
    selector: 'th[phxSortable]'
})
export class SortableHeaderDirective {
    @Input() phxSortable = '';
    @Input() phxDirection: SortDirection = '';
    @Output() phxSort = new EventEmitter<SortEvent>();

    @HostBinding('class.asc')
    get isAsc() {
        return this.phxDirection === 'asc';
    }

    @HostBinding('class.desc')
    get isDesc() {
        return this.phxDirection === 'desc';
    }

    @HostListener('click')
    rotate() {
        this.phxDirection = this.nextRotateDirection[this.phxDirection];
        this.phxSort.emit({ column: this.phxSortable, direction: this.phxDirection });
    }

    private nextRotateDirection: { [key: string]: SortDirection } = { asc: 'desc', desc: '', '': 'asc' };
}
