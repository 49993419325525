import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { getIsLoggedIn } from '../store/auth.selectors';

@Injectable({
    providedIn: 'root'
})
export class AuthLoadedGuard {
    constructor(private store$: Store) {}

    canActivate(): Observable<boolean> {
        return this.store$.select(getIsLoggedIn).pipe(
            filter(allLoaded => allLoaded != null),
            take(1)
        );
    }
}
