import { Injectable } from '@angular/core';
import { CmsPreviewModeService } from '../content/cms-preview-mode.service';
import { ContentItem } from '../content/content-item';
import { BreakpointService } from '../service/breakpoint.service';
import { PlatformService } from '../universal/platform.service';

export interface RestrictionCheckResult {
    isValid: boolean;
    isDynamicallyEvaluated: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class RestrictionCheckService {
    constructor(
        private breakpointService: BreakpointService,
        private platform: PlatformService,
        private cmsPreviewMode: CmsPreviewModeService
    ) {}

    // TODO: other restrictions
    // TODO: extract to different services
    check(contentItem: ContentItem) {
        if (this.cmsPreviewMode.ignoreRestrictions) {
            // to preview cms content, you might want to disable all client side restrictions to be able to review all cases
            return { isValid: true, isDynamicallyEvaluated: false } as RestrictionCheckResult;
        }

        if (contentItem.metadata.restrictions?.schedule) {
            const restriction = contentItem.metadata.restrictions.schedule;
            const parsedOnline = restriction.dateOnline && new Date(restriction.dateOnline);
            const parsedOffline = restriction.dateOffline && new Date(restriction.dateOffline);

            const currentDate = new Date();

            if (parsedOnline && parsedOnline > currentDate) {
                return { isValid: false, isDynamicallyEvaluated: false } as RestrictionCheckResult;
            }

            if (parsedOffline && parsedOffline < currentDate) {
                return { isValid: false, isDynamicallyEvaluated: false } as RestrictionCheckResult;
            }

            return { isValid: true, isDynamicallyEvaluated: false } as RestrictionCheckResult;
        }

        if (contentItem.metadata.restrictions?.device) {
            if (this.platform.isServer) {
                // we are on the server, so we don't know the device size yet, so we show the content item to be on the safe side
                // it will then be shown/hidden dynamically with css
                return { isValid: true, isDynamicallyEvaluated: true } as RestrictionCheckResult;
            }

            // on client side we know what device we are on
            for (const alias in contentItem.metadata.restrictions.device) {
                if (contentItem.metadata.restrictions.device[alias] && this.breakpointService.isActive(alias)) {
                    return { isValid: true, isDynamicallyEvaluated: false } as RestrictionCheckResult;
                }
            }

            return { isValid: false, isDynamicallyEvaluated: false } as RestrictionCheckResult;
        }

        if (contentItem.metadata.restrictions?.newsletter?.hiddenStatuses?.length) {
            // evaluated dynamically in DynamicComponentRestrictionContainerComponent
            return { isValid: true, isDynamicallyEvaluated: true } as RestrictionCheckResult;
        }

        if (contentItem.metadata.restrictions?.product) {
            // TODO
        }

        if (contentItem.metadata.restrictions?.category) {
            // TODO
        }

        return { isValid: true, isDynamicallyEvaluated: false } as RestrictionCheckResult;
    }

    get ignoreRestrictions() {
        return this.cmsPreviewMode.ignoreRestrictions;
    }
}
