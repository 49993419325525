import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, observeOn, switchMap, tap } from 'rxjs/operators';
import { ConfigurationService } from '../configuration/configuration.service';
import { LogDataMessage } from '../logging/log';
import { LoggerService } from '../logging/logger.service';
import { leaveNgZone } from '../rxjs-zone-scheduler/rx-ng-zone-scheduler';
import { ServerVarsService } from '../server-vars/server-vars.service';
import { PlatformService } from '../universal/platform.service';
import { Dictionary } from '../utils/dictionary.type';
import { ApiResponse } from './api-response';
import { ApiService } from './api.service';
import { BasicAuthService } from './basic-auth.service';

export enum HttpVerb {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
    PATCH = 'patch',
    HEAD = 'head'
}

export const defaultApiHeaders = {
    'Content-Type': 'application/json'
};

export class DefaultApiService implements ApiService {
    appVersion: string;

    constructor(
        private http: HttpClient,
        private config: ConfigurationService,
        private logger: LoggerService,
        private basicAuth: BasicAuthService,
        private serverVars: ServerVarsService,
        private platform: PlatformService,
        private ngZone: NgZone
    ) {
        this.appVersion = this.serverVars.getVersion() || 'n/a';
    }

    get<T>(
        requestPath: string,
        params?: { [id: string]: any } | HttpParams,
        options?: Record<string, unknown>
    ): Observable<T> {
        return this.doRequest(HttpVerb.GET, requestPath, params, undefined, { ...options });
    }

    delete<T>(
        requestPath: string,
        params?: { [id: string]: any } | HttpParams,
        body?: any,
        options?: Record<string, unknown>
    ): Observable<T> {
        return this.doRequest(HttpVerb.DELETE, requestPath, params, body, { ...options });
    }

    post<T>(
        requestPath: string,
        params?: { [id: string]: any } | HttpParams,
        body?: any,
        options?: Record<string, unknown>
    ): Observable<T> {
        return this.doRequest(HttpVerb.POST, requestPath, params, body, options);
    }

    put<T>(
        requestPath: string,
        params?: { [id: string]: any } | HttpParams,
        body?: any,
        options?: Record<string, unknown>
    ): Observable<T> {
        return this.doRequest(HttpVerb.PUT, requestPath, params, body, options);
    }

    download(requestPath: string, params?: { [id: string]: any } | HttpParams): Observable<Blob> {
        const options = {
            headers: new HttpHeaders({}),
            observe: 'body' as const,
            responseType: 'blob' as const,
            withCredentials: true,
            params
        };

        this.addBasicAuthIfNeeded(options);

        const finalUrl = `${this.config.get('apiHost')}/${this.config.get('apiVersion')}/${requestPath}`;
        this.logger.debug('[API] downloading file blob url: ' + finalUrl);

        return this.http.get(finalUrl, options);
    }

    private doRequest<T>(
        verb: HttpVerb,
        requestPath: string,
        params?: { [id: string]: any } | HttpParams,
        body?: any,
        options?: any
    ) {
        const defaultOptions = {
            headers: new HttpHeaders(defaultApiHeaders),
            responseType: 'json',
            withCredentials: true,
            runOutsideOfNgZone: false
        };

        params = this.trimUndefinedParams(params);

        const optionsToUse = {
            ...defaultOptions,
            ...options,
            params
        };

        this.addBasicAuthIfNeeded(optionsToUse);

        this.addVersionInformationHeaders(optionsToUse);

        const finalUrl = `${this.config.get('apiHost')}/${this.config.get('apiVersion')}/${requestPath}`;
        this.logger.debug(
            `[API] ${verb.toUpperCase()} request to ${finalUrl}${params ? '?' + this.getParamsString(params) : ''}`
        );

        let request = null;
        switch (verb) {
            case HttpVerb.POST:
                request = this.http.post<ApiResponse<T>>(finalUrl, body, optionsToUse);
                break;
            case HttpVerb.PUT:
                request = this.http.put<ApiResponse<T>>(finalUrl, body, optionsToUse);
                break;
            case HttpVerb.GET:
                request = this.http.get<ApiResponse<T>>(finalUrl, optionsToUse);
                break;
            case HttpVerb.DELETE:
                optionsToUse['body'] = body;
                request = this.http.delete<ApiResponse<T>>(finalUrl, optionsToUse);
                break;
            default:
                throw new Error(`Http verb ${verb} not supported.`);
        }

        return of(true).pipe(
            optionsToUse.runOutsideOfNgZone ? observeOn(leaveNgZone(this.ngZone)) : tap(/* noop */),
            switchMap(() =>
                request.pipe(
                    map((response: ApiResponse<T> | HttpResponse<ApiResponse<T>>) => {
                        if (optionsToUse['observe'] === 'response') {
                            // we get the full response not just the body
                            const fullResponse = response as HttpResponse<ApiResponse<T>>;

                            return {
                                ...fullResponse.body,
                                headers: fullResponse.headers,
                                status: fullResponse.status
                            } as ApiResponse<T>;
                        }

                        return (response as ApiResponse<T>)?.data;
                    }),
                    tap({
                        next: this.logPerformance(verb, finalUrl, params),
                        error: this.logPerformance(verb, finalUrl, params)
                    })
                )
            )
        ) as any;
    }

    private addBasicAuthIfNeeded(options: Dictionary<any>) {
        if (this.basicAuth && this.basicAuth.needsBasicAuth) {
            this.logger.debug(`[API] adding basic authentication header with '${this.basicAuth.basicAuthCredentials}'`);
            options.headers = options.headers.set('Authorization', this.basicAuth.basicAuthCredentials);
        }
    }

    private addVersionInformationHeaders(options: Dictionary<any>) {
        options.headers = options.headers.set('X-PHX-Version', this.appVersion);
    }

    private trimUndefinedParams(allParams) {
        if (!allParams || allParams instanceof HttpParams) {
            return allParams;
        }

        return Object.keys(allParams).reduce((trimmedParams, key) => {
            if (allParams[key] !== undefined) {
                trimmedParams[key] = allParams[key];
            }
            return trimmedParams;
        }, {});
    }

    private logPerformance(verb: HttpVerb, finalUrl: string, params?: { [id: string]: any } | HttpParams) {
        const timingStart = new Date();
        return () => {
            if (
                (this.platform.isServer && this.config.get('enableDetailedRequestTimeLoggingForServer', true)) ||
                (this.platform.isBrowser && this.config.get('enableDetailedRequestTimeLoggingForBrowser', true))
            ) {
                const requestTime = new Date().getTime() - timingStart.getTime();
                const requestUrl = `${finalUrl}${params ? '?' + this.getParamsString(params) : ''}`;
                const requestMethod = verb.toUpperCase();
                const logData = [] as LogDataMessage[];

                logData.push(['requestTime', requestTime]);
                logData.push(['requestUrl', requestUrl]);
                logData.push(['requestMethod', requestMethod]);

                this.logger.info(
                    `[API] Finished ${requestMethod} request to ${requestUrl} in ${requestTime}ms`,
                    ...logData
                );
            }
        };
    }

    private getParamsString(params: { [id: string]: any } | HttpParams) {
        if (params instanceof HttpParams) {
            return params.toString();
        }

        return new URLSearchParams(params as any).toString();
    }
}
