import { HttpErrorResponse, HttpParams, HttpRequest } from '@angular/common/http';
import { TimeoutError, throwError } from 'rxjs';
import { ApiError, ApiErrorCustomCodes } from '../error-handling/api-error';
import { ApiResponse } from './api-response';

export function convertParamsToRequestPath(requestPath: string, params?: { [id: string]: any } | HttpParams) {
    if (params) {
        const paramsAsString = Object.keys(params)
            .reduce((combined, key) => `${combined}&${key}=${params[key]}`, '')
            .substring(1);
        return `${requestPath}${requestPath.indexOf('?') > -1 ? '&' : '?'}${paramsAsString}`;
    }

    return requestPath;
}

export function handleHttpError(
    error: HttpErrorResponse | Error,
    request: HttpRequest<any>,
    customErrorOverrides: (response: HttpErrorResponse, apiError: ApiError) => void = () => {}
) {
    const requestUrl = request.urlWithParams;

    try {
        if (error instanceof HttpErrorResponse) {
            const response = error as HttpErrorResponse;

            if (response.error && response.error.error) {
                // server-side error (HttpErrorResponse with ApiResponse body containing error message)
                const apiResponse = response.error as ApiResponse<any>;
                const logMessage =
                    `[API] Server-side error (code: ${response.status}, message: ` +
                    `'${apiResponse.error.message || response.message || ''}', id: '${
                        response.error.id || ''
                    }') for request: '${requestUrl}'`;
                const apiError = new ApiError(response.status, response.error.error, response.error.id, logMessage);

                // Application specific error modifications and handling
                customErrorOverrides(response, apiError);

                return throwError(() => apiError);
            } else if (response.error?.activityId && response.error?.statusCode && response.status === 503) {
                // APIM backend timeout error
                const logMessage =
                    `[API] APIM backend timeout error (code: ${response.status}, message: ` +
                    `'${response.error?.message || ''}', activityId: '${
                        response.error?.activityId || ''
                    }') for request: '${requestUrl}'`;
                const apiError = new ApiError(response.status, `APIM backend timeout error`, null, logMessage);

                return throwError(() => apiError);
            } else {
                // client-side network error (HttpErrorResponse with message)
                const code = response.status || ApiErrorCustomCodes.ClientNetworkError;
                const logMessage = `[API] Client-side network error (code: ${code}, message: '${response.message}') for request: '${requestUrl}'`;
                const apiError = new ApiError(code, null, null, logMessage);

                return throwError(() => apiError);
            }
        } else if (error instanceof TimeoutError) {
            // client-side timeout error
            const logMessage = `[API] Client-side timeout for request: '${requestUrl}'`;
            const apiError = new ApiError(ApiErrorCustomCodes.ClientTimeoutError, null, null, logMessage);

            return throwError(() => apiError);
        } else {
            // client-side unhandled error type error
            const logMessage = `[API] Client-side error with unhandled type ('${
                error.message || error.constructor.name
            }') for request: '${requestUrl}' (${error})`;
            const apiError = new ApiError(ApiErrorCustomCodes.ClientUnhandledErrorTypeError, null, null, logMessage);

            return throwError(() => apiError);
        }
    } catch (e) {
        // client-side error handling error
        const logMessage = `[API] Client-side error ('${e.message}') handling error ('${error.message}') for request: '${requestUrl}'`;
        const apiError = new ApiError(ApiErrorCustomCodes.ClientErrorHandlingError, null, null, logMessage);

        return throwError(() => apiError);
    }
}
