import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ServerVarsService } from '../server-vars/server-vars.service';
import { PlatformService } from '../universal/platform.service';
import { ServerService } from '../universal/server.service';
import { getCircularReplacer } from '../utils/circular-replacer';
import { Dictionary } from '../utils/dictionary.type';
import { ErrorLog, Log, LogDataMessage, LogLevel, LogMessage } from './log';

@Injectable({
    providedIn: 'root'
})
export class BasicLoggerService {
    private logQueue$ = new ReplaySubject<string>();
    private isSSR: boolean;
    private version: string;

    constructor(
        private http: HttpClient,
        private serverService: ServerService,
        private serverVars: ServerVarsService,
        platform: PlatformService
    ) {
        this.isSSR = platform.isServer;
        this.version = serverVars.getVersion();
    }

    get logQueueObservable() {
        return this.logQueue$ as Observable<string>;
    }

    log(
        messages: LogMessage[],
        level: LogLevel,
        extras = {},
        isClientLogLevelAllowed = true,
        isLogLevelAllowed = true,
        logWithoutLogEndpointAnyway = false
    ) {
        const clientDate = new Date();
        const messageStrings = messages.filter(m => typeof m === 'string');
        // make misc. log data more suitable for log handling and searching
        // convert to style { 'data_name': 'json_string_of_data'};
        // this should end up in the field `angular.data.data_name` in Kibana
        const messageData = messages
            .filter(m => typeof m !== 'string')
            .reduce((mergedDataMessages, m: LogDataMessage) => {
                if (m[1] instanceof Object) {
                    mergedDataMessages[m[0]] = JSON.stringify(m[1]);
                } else {
                    mergedDataMessages[m[0]] = m[1];
                }

                return mergedDataMessages;
            }, {}) as Dictionary<string>;

        const finalLog: Log = {
            level,
            message: messageStrings && messageStrings.join(' '),
            isSSR: this.isSSR,
            clientTimestamp:
                clientDate.toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                }) +
                ' @ ' +
                `${clientDate.getHours().toString().padStart(2, '0')}:${clientDate
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}:${clientDate.getSeconds().toString().padStart(2, '0')}.${clientDate
                    .getMilliseconds()
                    .toString()
                    .padStart(3, '0')}`,
            url: this.isSSR
                ? this.serverService.request.protocol +
                  '://' +
                  this.serverService.request.get('host') +
                  this.serverService.request.originalUrl
                : window.location.href,
            userAgent: this.isSSR ? this.serverService.request.header('User-Agent') : window.navigator.userAgent,
            version: this.version,
            tabId: this.isSSR ? undefined : window['shp_TABID'],
            data: messageData,
            ...extras
        };

        // logging to client console
        if (isClientLogLevelAllowed && !this.isSSR) {
            if (finalLog.level === 'error' && (finalLog as ErrorLog).error) {
                console.error((finalLog as ErrorLog).error, ...messages);
            } else {
                //eslint-disable-next-line no-console
                console[level](...messages);
            }
        }

        // logging to graylog
        const env = this.serverVars.getEnvironment();
        // there is no log endpoint when running local dev server (there is though, when running with local docker (port 3000))
        const localEnvWithNoLogEndpoint = !this.isSSR && env === 'local' && window.location.port !== '3000';

        if (isLogLevelAllowed) {
            if (!localEnvWithNoLogEndpoint) {
                this.sendLogToEndpoint(finalLog);
            } else if (localEnvWithNoLogEndpoint && logWithoutLogEndpointAnyway) {
                this.sendLogToEndpoint(finalLog);
            }
        }
    }

    private sendLogToEndpoint(log: Log) {
        // clean the Log object from circular dependencies and
        // base64 encode it to satisfy security checks in the Cloudflare firewall
        // using unescape(encodeURIComponent()) to convert latin1 (umlauts) to utf8
        const cleanedEncodedLog = btoa(unescape(encodeURIComponent(JSON.stringify(log, getCircularReplacer()))));

        // Use this to locally debug log payload in plaintext
        //const cleanedEncodedLog = JSON.parse(JSON.stringify(log, getCircularReplacer()));

        if (this.isSSR) {
            this.http
                .post(`./log`, [cleanedEncodedLog], {
                    headers: new HttpHeaders({
                        ...(this.serverService.request?.headers?.['cf-connecting-ip'] && {
                            'cf-connecting-ip': this.serverService.request?.headers?.['cf-connecting-ip']
                        }),
                        ...(this.serverService.request?.headers?.['x-forwarded-for'] && {
                            'x-forwarded-for': this.serverService.request?.headers?.['x-forwarded-for']
                        })
                    })
                })
                .subscribe();
        } else {
            this.logQueue$.next(cleanedEncodedLog);
        }
    }
}
