import { Injectable } from '@angular/core';
import { ApiService, LoggerService } from '@phx/core';
import { Observable, tap } from 'rxjs';
import { AdyenOnboardingData, AdyenOnboardingUrl } from './adyen-onboarding-data';

@Injectable({
    providedIn: 'root'
})
export class AdyenOnboardingService {
    constructor(private api: ApiService, private logger: LoggerService) {}

    getAdyenAccountDetails(): Observable<AdyenOnboardingData> {
        return this.api.get<AdyenOnboardingData>('merchants/payments/adyen/account').pipe(
            tap({
                next: response =>
                    this.logger.debug(`[MERCHANT-SERVICE] get AdyenAccountDetails successful`, ['response', response]),
                error: error => this.logger.debug(`[MERCHANT-SERVICE] get AdyenAccountDetails failed`, ['error', error])
            })
        );
    }

    startAdyenAccountCreation(): Observable<AdyenOnboardingData> {
        return this.api.post<AdyenOnboardingData>('merchants/payments/adyen/account').pipe(
            tap({
                next: response =>
                    this.logger.debug(`[MERCHANT-SERVICE] post startAdyenOnboarding successful`, [
                        'response',
                        response
                    ]),
                error: error =>
                    this.logger.debug(`[MERCHANT-SERVICE] post startAdyenOnboarding failed`, ['error', error])
            })
        );
    }

    getAdyenOnboardingUrl(): Observable<AdyenOnboardingUrl> {
        return this.api.post<AdyenOnboardingUrl>('merchants/payments/adyen/account/onboarding').pipe(
            tap({
                next: response =>
                    this.logger.debug(`[MERCHANT-SERVICE] get AdyenOnboardingUrl successful`, ['response', response]),
                error: error => this.logger.debug(`[MERCHANT-SERVICE] get AdyenOnboardingUrl failed`, ['error', error])
            })
        );
    }
}
