<ng-container *phxVar="user$ | async as user">
    <div *ngIf="user != null" class="container">
        <p>
            Eingeloggt als <b>{{ user.uid }}</b>
        </p>

        <button class="btn btn-primary" (click)="logout()">Logout</button>
    </div>

    <div *ngIf="user == null" class="login-form container bg-light border rounded p-3">
        <form (ngSubmit)="login()">
            <div class="mb-3">
                <label for="inputUsername" class="form-label">Username</label>
                <input type="text" class="form-control" id="inputUsername" [formControl]="usernameControl" />
            </div>

            <div class="mb-3">
                <label for="inputPassword" class="form-label">Password</label>
                <input type="text" class="form-control" id="inputPassword" [formControl]="passwordControl" />
            </div>

            <button type="submit" class="btn btn-primary">Login</button>
        </form>
    </div>
</ng-container>
