import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'phx-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements AfterViewInit {
    @ViewChild('bodyContent') bodyContent: ElementRef<HTMLElement>;
    isCollapsed = true;
    hasBodyContent = false;

    constructor(private cd: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.hasBodyContent = this.bodyContent.nativeElement && this.bodyContent.nativeElement.children.length > 0;
        this.cd.detectChanges();
    }
}
