import { Injectable } from '@angular/core';
import { ServerService } from './server.service';

@Injectable({
    providedIn: 'root'
})
export class BrowserOnlyServerService implements ServerService {
    public get request() {
        return {};
    }

    public get response() {
        return {};
    }
}
