import { Injectable } from '@angular/core';
import { CookieService } from '../service';

@Injectable({
    providedIn: 'root'
})
export class BasicAuthService {
    get needsBasicAuth() {
        return !!this.cookie.get('phx-ba');
    }

    get basicAuthCredentials() {
        return this.cookie.get('phx-ba');
    }

    constructor(private cookie: CookieService) {}
}
