import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { ConfigurationService } from '../configuration';

export const FORCE_REFRESH_QUERYPARAM_KEY = '__refresh';
export const FORCE_RELOAD_QUERYPARAM_KEY = '__reload';
export const FORCE_REUSE_QUERYPARAM_KEY = '__reuse';
export const PREVENT_ROUTE_REFRESH = 'preventRouteRefresh';

@Injectable()
export class PhxRouteReuseStrategy implements RouteReuseStrategy {
    private specialFilterParameterNames: string[];

    constructor(private config: ConfigurationService) {
        this.specialFilterParameterNames =
            this.config.get('specialFilters', true)?.map((o: any) => o['filterName']) || [];
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        //should never be called, since we are not reusing routes
        return;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        //should never be called, since we are not reusing routes
        return null;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        // FROM DEFAULT STRATEGY: return future.routeConfig === curr.routeConfig;

        //return this.noReuse(future, curr);
        return this.ionicReuse(future, curr);
    }

    private noReuse(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return false;
    }

    private ionicReuse(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        // special query param to force a reuse of the current route
        // it is used to correct/replace the router URL of the current page without recreating any components or triggering resolvers
        // (the previous method, using location.replaceState(), caused issues with route reuse when navigating via history back/forward)
        if (
            future.queryParamMap.has(FORCE_REUSE_QUERYPARAM_KEY) ||
            current.queryParamMap.has(FORCE_REUSE_QUERYPARAM_KEY)
        ) {
            return true;
        }

        // reuse route if route param values are the same (e.g. /c/hosen !== /c/buecher for routeConfig /c/:catId)
        if (future.routeConfig !== current.routeConfig) {
            return false;
        }

        // wildcard routes would always be reused if not checked for different url
        if (current.routeConfig?.path === '**' && future.routeConfig?.path === '**') {
            if (
                current.url?.reduce((fullPath, pathSegment) => {
                    return fullPath + '/' + pathSegment;
                }, '') !==
                future.url?.reduce((fullPath, pathSegment) => {
                    return fullPath + '/' + pathSegment;
                }, '')
            ) {
                return false;
            }
        }

        if (future.component !== current.component && future.parent != null && current.parent != null) {
            // on the first route change after bootstrap the current component is null for whatever reason :(
            // but it still can be the same component, so we need to check more to see if we can reuse the route/component
            // e.g. full page reload on a listing page and filter or go to the next page. first time should be reused as well,
            // but curr.component is null
            if (current.component !== null) {
                return false;
            } else if (!this.compareChildren(current.children, future.children)) {
                return false;
            }
        }

        // checking router params
        const futureParams = future.params;
        const currentParams = current.params;
        const keysA = Object.keys(futureParams);
        const keysB = Object.keys(currentParams);

        if (keysA.length !== keysB.length) {
            return false;
        }

        // Test for A's keys different from B.
        for (const key of keysA) {
            if (currentParams[key] !== futureParams[key]) {
                return false;
            }
        }

        // special query param to force reload. only the first time it is added
        // TODO: check if we want: currently only for childs of storefrontpage component and not the complete page structure
        if (
            future.queryParamMap.has(FORCE_REFRESH_QUERYPARAM_KEY) &&
            !current.queryParamMap.has(FORCE_REFRESH_QUERYPARAM_KEY) &&
            future.component?.['_ignoreOnRouteRefresh'] !== true // do not refresh App- and Storefront components (and others that have that flag set)
        ) {
            return false;
        }

        if (
            this.hasSpecialFiltersInQueryString(current, future) &&
            future.component?.['_ignoreOnRouteRefresh'] !== true // do not refresh App- and Storefront components (and others that have that flag set)
        ) {
            // refresh route if we have special filters in the PLPs `filter` query string param
            // because we need to check if we need to render custom pages (configured in CMS) for the given filters
            return false;
        }

        return true;
    }

    private compareChildren(childrenA: ActivatedRouteSnapshot[], childrenB: ActivatedRouteSnapshot[]) {
        if (childrenA.length !== childrenB.length) {
            return false;
        }

        for (const indexA in childrenA) {
            if (childrenA[indexA] && childrenB[indexA]) {
                if (childrenA[indexA].component !== childrenB[indexA].component) {
                    return false;
                }

                if (!this.compareChildren(childrenA[indexA].children, childrenB[indexA].children)) {
                    return false;
                }
            } else {
                return false;
            }
        }

        return true;
    }

    private hasSpecialFiltersInQueryString(current: ActivatedRouteSnapshot, future: ActivatedRouteSnapshot) {
        const currentFilters = current.queryParamMap.getAll('filter');
        const futureFilters = future.queryParamMap.getAll('filter');

        if (currentFilters.length === 0 && futureFilters.length === 0) {
            return false;
        }

        for (let i = 0; i < this.specialFilterParameterNames.length; i++) {
            if (
                [...currentFilters, ...futureFilters]
                    .map(s => s.split(':')[0])
                    .some(f => f === this.specialFilterParameterNames[i])
            ) {
                return true;
            }
        }
    }
}

/**
 * Does not detach any subtrees. Reuses routes as long as their route config is the same.
 */
export class DefaultRouteReuseStrategy implements RouteReuseStrategy {
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }
    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null;
    }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }
}
