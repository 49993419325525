export const isBrowser = typeof document !== 'undefined';

export const isIE11 = isBrowser && !!window['MSInputMethodContext'] && !!document['documentMode'];

export const isSafari = typeof navigator !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isIOS =
    isBrowser &&
    (!!window.navigator.userAgent.match(/iP(ad|hone|od)/) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)); // iPad on iOS 13 detection
