import { Injectable } from '@angular/core';
import { LazyModuleHelper } from './lazy-module-helper.service';

// global lookup table for lazy loaded dynamic components (which module the component belongs to)
// TODO: add all components that can be configured via CMS that are not part of the initial page load (maybe split up dynamic-content module)
// NOTE: this needs to be explicit/hardcoded as otherwise webpack complains that it cannot analyse dependencies if expressions are used for import statements
// NOTE: this also has to be in this specific syntax so the angular aot compiler recognises it (https://angular.io/guide/deprecations#loadchildren-string-syntax)

const lazyMap = [];

@Injectable()
export class EmptyLazyModuleHelper implements LazyModuleHelper {
    getLazyModuleImporter(componentName: string) {
        return lazyMap.find(r => r.componentName === componentName);
    }
}
