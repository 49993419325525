import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceSet } from './content-item';

@Injectable({
    providedIn: 'root'
})
export class ResourceSetCacheService {
    cachedResourceSets: Record<string, Observable<ResourceSet>>;

    constructor() {
        this.cachedResourceSets = {};
    }

    getResourceSet(id: string) {
        return this.cachedResourceSets[id];
    }

    addResourceSet(id: string, entries$: Observable<ResourceSet>) {
        this.cachedResourceSets[id] = entries$;
    }
}
