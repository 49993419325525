import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentItem, DynamicComponentService, ResourceSetService } from '@phx/core';
import { DynamicComponentDirective } from '@phx/core-exports';
import { format } from 'date-fns';
import { KpiMonth, KpisMonthly } from '../services/kpis';
import { MerchantService } from '../services/merchant.service';
import { SortEvent, SortableHeaderDirective } from '../utils/sortable-header.directive';
import { compare, getObjProperty } from '../utils/utils';

@Component({
    selector: 'phx-kpis-component',
    templateUrl: './kpis.component.html',
    styleUrls: ['./kpis.component.scss']
})
export class KpisComponent implements OnInit {
    kpiData: KpisMonthly;
    sortedKpiMonths: KpiMonth[];
    loading = false;
    hasError = false;

    @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

    @ViewChild('componentPlaceholderAbove', { read: DynamicComponentDirective, static: true })
    componentPlaceholderAbove: DynamicComponentDirective;

    @ViewChild('componentPlaceholderBelow', { read: DynamicComponentDirective, static: true })
    componentPlaceholderBelow: DynamicComponentDirective;

    constructor(
        public router: Router,
        private merchantService: MerchantService,
        private resourceSetService: ResourceSetService,
        private dynamicComponentService: DynamicComponentService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title
    ) {
        this.getKpiData();
    }

    ngOnInit(): void {
        this.getResourceSet();
        this.titleService.setTitle(this.activatedRoute.snapshot.data?.['title']);
    }

    onSort({ column, direction }: SortEvent) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.phxSortable !== column) {
                header.phxDirection = '';
            }
        });

        // sort
        if (direction === '' || column === '') {
            this.sortedKpiMonths = this.kpiData.months;
        } else {
            this.sortedKpiMonths = [...this.kpiData.months].sort((a, b) => {
                const res = compare(getObjProperty(a, column), getObjProperty(b, column));
                return direction === 'asc' ? res : -res;
            });
        }
    }

    private getKpiData() {
        this.loading = true;
        this.hasError = false;

        const fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 12);
        const toDate = new Date();

        this.merchantService.getKpisMonthly(this.formatDateForApi(fromDate), this.formatDateForApi(toDate)).subscribe({
            next: kpiResponse => {
                this.kpiData = kpiResponse;

                // default sorting
                this.sortedKpiMonths = this.kpiData?.months;

                this.loading = false;
            },
            error: error => {
                this.loading = false;
                this.hasError = true;
            }
        });
    }

    private getResourceSet() {
        this.resourceSetService.getSiteContent().subscribe(resources => {
            this.dynamicComponentService.renderComponentList(
                resources['mc.kpis.content-above-table'] as ContentItem[],
                this.componentPlaceholderAbove
            );

            this.dynamicComponentService.renderComponentList(
                resources['mc.kpis.content-below-table'] as ContentItem[],
                this.componentPlaceholderBelow
            );
        });
    }

    private formatDateForApi(date: Date) {
        return format(date, 'yyyy-MM');
    }
}
