import { ActivatedRouteSnapshot, Navigation, RouterStateSnapshot, convertToParamMap } from '@angular/router';
import { PhxRouterStateSnapshot } from './phx-router-state-snapshot';
import { extractPrimaryPath, objectFromParamMap } from './utils';

export function serializeRouterState(
    routerState: RouterStateSnapshot,
    currentNavigation: Navigation = null,
    previousUrl: string = null
): PhxRouterStateSnapshot {
    const url = routerState.url;
    // remove unwanted 'bot' query param (needed for cloudflare worker requests) to avoid having it in generated hrefs/redirects
    const queryParamsObject = objectFromParamMap(routerState.root.queryParamMap);
    delete queryParamsObject['bot'];
    const queryParamMap = convertToParamMap(queryParamsObject);

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
        state = state.firstChild;
    }

    const { paramMap, data, routeConfig, fragment, outlet } = state;

    const primaryPath = extractPrimaryPath(routerState.url);

    const trigger = currentNavigation?.trigger;

    const extras = currentNavigation?.extras;

    const onlyAnchorNavigation = previousUrl?.split('#')[0] === url.split('#')[0];

    return {
        routeConfig,
        url,
        queryParamMap,
        paramMap,
        primaryPath,
        data,
        fragment,
        outlet,
        trigger,
        extras,
        onlyAnchorNavigation
    };
}
