<header>
    <nav class="navbar navbar-expand-sm navbar-light bg-light border-bottom mb-3">
        <div class="container-fluid">
            <a class="navbar-brand" [href]="config['mppHomeUrl']"
                ><img src="/assets/shp-logo.png" width="125px" alt="cockpit-home"
            /></a>

            <div class="navbar-collapse">
                <ul ngbNav [activeId]="routerState.primaryPath" class="navbar-nav me-auto mb-0">
                    <li class="nav-item">
                        <a [href]="config['mppHomeUrl']" class="nav-link">Zurück zum MPP</a>
                    </li>

                    <ng-container *ngFor="let link of navigationLinks">
                        <li
                            *ngIf="routerState.primaryPath === link.url || config['showFullNav']"
                            [ngbNavItem]="link.url"
                            class="nav-item"
                        >
                            <a ngbNavLink [routerLink]="link.url" class="nav-link">{{ link.name }}</a>
                        </li>
                    </ng-container>

                    <li [ngbNavItem]="'/login'" class="nav-item" *ngIf="config['showLogin']">
                        <a ngbNavLink routerLink="/login" class="nav-link">Login</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>

<div *ngIf="(user$ | async)?.error as error" class="text-center">
    <ngb-alert type="danger" [dismissible]="false">{{ error.message }}</ngb-alert>
</div>

<router-outlet></router-outlet>
