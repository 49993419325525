import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ContentItem, DynamicComponentService, ResourceSet, ResourceSetService } from '@phx/core';
import { DynamicComponentDirective } from '@phx/core-exports';
import { Observable, Subject, filter, switchMap, take, takeUntil, tap, timer } from 'rxjs';
import {
    AdyenOnboardingData,
    AdyenOnboardingStatus,
    AdyenOnboardingUrl,
    Capability,
    CapabilityDisplayStatus,
    CapabilityProblemError
} from '../services/adyen-onboarding-data';
import { AdyenOnboardingService } from '../services/adyen-onboarding.service';

@Component({
    selector: 'phx-adyen-onboarding',
    templateUrl: './adyen-onboarding.component.html',
    styleUrls: ['./adyen-onboarding.component.scss']
})
export class AdyenOnboardingComponent implements OnInit, OnDestroy {
    resources: ResourceSet;
    loading = false;
    isButtonClickLoading = false;
    hasError = false;
    adyenOnboardingData$: Observable<AdyenOnboardingData>;
    destroy$ = new Subject<void>();
    adyenOnboadingPageUrl: string;
    disableButton = false;

    isCollapsedAccountStatus = true;

    capabilityDisplayStatus = CapabilityDisplayStatus;
    adyenOnboardingStatus = AdyenOnboardingStatus;

    adyenOnboardingStatusToCssClassMap = new Map<string, string>([
        ['active', 'bg-success'],
        ['inactive', 'bg-warning'],
        ['suspended', 'bg-danger'],
        ['closed', 'bg-secondary'],
        [CapabilityDisplayStatus.OK, 'bg-success'],
        [CapabilityDisplayStatus.PENDING, 'bg-info'],
        [CapabilityDisplayStatus.ACTION_REQUIRED, 'bg-danger'],
        [CapabilityDisplayStatus.DEACTIVATED, 'bg-secondary']
    ]);

    @ViewChild('subTextComponentPlaceholder', { read: DynamicComponentDirective, static: true })
    subTextComponentPlaceholder: DynamicComponentDirective;

    @ViewChild('belowTableInfoComponentPlaceholder', { read: DynamicComponentDirective, static: true })
    belowTableInfoComponentPlaceholder: DynamicComponentDirective;

    constructor(
        private resourceSetService: ResourceSetService,
        private adyenOnboardingService: AdyenOnboardingService,
        private dynamicComponentService: DynamicComponentService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private cd: ChangeDetectorRef
    ) {
        this.initAdyenAccountDetails();
    }

    ngOnInit(): void {
        this.resourceSetService.getSiteContent().subscribe(res => {
            this.resources = res;
            this.dynamicComponentService.renderComponent(
                this.resources['mc.adyenOnboarding.subText'] as ContentItem,
                this.subTextComponentPlaceholder
            );

            this.dynamicComponentService.renderComponent(
                this.resources['mc.adyenOnboarding.belowTable.info'] as ContentItem,
                this.belowTableInfoComponentPlaceholder
            );
        });

        this.titleService.setTitle(this.activatedRoute.snapshot.data?.['title']);

        this.startAdyenOnboardingPageLinkPolling();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    requestAdyenAccountCreation() {
        this.isButtonClickLoading = true;
        this.hasError = false;
        this.disableButton = true;
        this.adyenOnboardingService
            .startAdyenAccountCreation()
            .pipe(
                tap({
                    next: res => {
                        this.isButtonClickLoading = false;
                        this.disableButton = false;
                    },
                    error: error => {
                        this.isButtonClickLoading = false;
                        this.hasError = true;
                        this.disableButton = false;
                    }
                })
            )
            .subscribe(res => {
                this.initAdyenAccountDetails();
            });
    }

    private initAdyenAccountDetails() {
        this.loading = true;
        this.hasError = false;
        this.adyenOnboardingData$ = this.adyenOnboardingService.getAdyenAccountDetails().pipe(
            tap({
                next: res => {
                    this.loading = false;
                },
                error: error => {
                    this.loading = false;
                    this.hasError = true;
                }
            })
        );
    }

    startAdyenOnboardingPageLinkPolling() {
        this.adyenOnboardingData$
            .pipe(
                filter(res => res.adyenStatus === AdyenOnboardingStatus.READY_FOR_HOP),
                switchMap(ready =>
                    // refresh the link every 3:50 minutes and do it at maximum 10 times, the link expires every 4 minutes
                    // (Patrick: also i asked Frank - HOP url lifetime = 4 minutes)
                    timer(0, 230000).pipe(switchMap(t => this.getAdyenOnboardingPageLink()))
                ),
                take(10),
                takeUntil(this.destroy$)
            )
            .subscribe({
                next: res => {
                    this.adyenOnboadingPageUrl = res.url;
                    this.cd.markForCheck();
                },
                complete: () => {
                    // when the observable completes we set the url to be empty, then when the user clicks the button
                    // this observable gets started again
                    this.adyenOnboadingPageUrl = '';
                    this.cd.markForCheck();
                }
            });
    }

    private getAdyenOnboardingPageLink(): Observable<AdyenOnboardingUrl> {
        this.adyenOnboadingPageUrl = '';
        this.isButtonClickLoading = true;
        this.hasError = false;
        this.disableButton = true;
        return this.adyenOnboardingService.getAdyenOnboardingUrl().pipe(
            tap({
                next: res => {
                    this.isButtonClickLoading = false;
                    this.disableButton = false;
                },
                error: error => {
                    this.isButtonClickLoading = false;
                    this.hasError = true;
                    this.disableButton = false;
                }
            })
        );
    }

    navigateToAdyenOnboardingPageIfActionRequired($event: Event, status: CapabilityDisplayStatus) {
        if (status === CapabilityDisplayStatus.ACTION_REQUIRED) {
            $event.preventDefault();
            $event.stopPropagation();
            this.navigateToAdyenOnboardingPage();
        }
    }

    navigateToAdyenOnboardingPage() {
        if (!this.adyenOnboadingPageUrl) {
            this.startAdyenOnboardingPageLinkPolling();
        } else {
            window.open(this.adyenOnboadingPageUrl);
        }
    }

    compareFromBalanceCapabilities(response: AdyenOnboardingData): CapabilityDisplayStatus {
        if (!response?.accountHolderData?.capabilities) {
            return undefined;
        }

        return this.compareCapabilities(
            this.getCapabilitiesStatus(response.accountHolderData.capabilities.receiveFromBalanceAccount),
            this.getCapabilitiesStatus(response.accountHolderData.capabilities.receiveFromPlatformPayments)
        );
    }

    compareToBalanceCapabilities(response: AdyenOnboardingData): CapabilityDisplayStatus {
        if (!response?.accountHolderData?.capabilities) {
            return undefined;
        }

        return this.compareCapabilities(
            this.getCapabilitiesStatus(response.accountHolderData.capabilities.sendToBalanceAccount),
            this.getCapabilitiesStatus(response.accountHolderData.capabilities.sendToTransferInstrument)
        );
    }

    private getCapabilitiesStatus(capability: Capability): CapabilityDisplayStatus {
        if (!capability) {
            return null;
        }
        switch (true) {
            case capability.enabled && capability.verificationStatus == 'valid':
                return CapabilityDisplayStatus.OK;
            case capability.enabled && capability.verificationStatus == 'pending':
                return CapabilityDisplayStatus.PENDING;
            case capability.enabled && capability.verificationStatus == 'invalid':
                return CapabilityDisplayStatus.ACTION_REQUIRED;
            case !capability.enabled:
                return CapabilityDisplayStatus.DEACTIVATED;
            default:
                return CapabilityDisplayStatus.ACTION_REQUIRED;
        }
    }

    private compareCapabilities(
        capabilityStatusA: CapabilityDisplayStatus,
        capabilityStatusB: CapabilityDisplayStatus
    ): CapabilityDisplayStatus {
        if (capabilityStatusA === capabilityStatusB) {
            return capabilityStatusA;
        }

        if (
            capabilityStatusA === CapabilityDisplayStatus.DEACTIVATED ||
            capabilityStatusB === CapabilityDisplayStatus.DEACTIVATED
        ) {
            return CapabilityDisplayStatus.DEACTIVATED;
        }

        if (
            capabilityStatusA === CapabilityDisplayStatus.ACTION_REQUIRED ||
            capabilityStatusB === CapabilityDisplayStatus.ACTION_REQUIRED
        ) {
            return CapabilityDisplayStatus.ACTION_REQUIRED;
        }

        if (
            capabilityStatusA === CapabilityDisplayStatus.PENDING ||
            capabilityStatusB === CapabilityDisplayStatus.PENDING
        ) {
            return CapabilityDisplayStatus.PENDING;
        }

        return CapabilityDisplayStatus.OK;
    }

    getReceiveErrors(response: AdyenOnboardingData): CapabilityProblemError[] {
        const capabilities = response?.accountHolderData?.capabilities;
        if (!capabilities) {
            return null;
        }
        const receiveProblems = [
            ...(capabilities.receiveFromBalanceAccount?.problems?.flatMap(p => p.verificationErrors) || []),
            ...(capabilities.receiveFromPlatformPayments?.problems?.flatMap(p => p.verificationErrors) || [])
        ];
        return receiveProblems.length ? receiveProblems : null;
    }

    getSendErrors(response: AdyenOnboardingData): CapabilityProblemError[] {
        const capabilities = response?.accountHolderData?.capabilities;
        if (!capabilities) {
            return null;
        }
        const sendProblems = [
            ...(capabilities?.sendToBalanceAccount?.problems?.flatMap(p => p.verificationErrors) || []),
            ...(capabilities?.sendToTransferInstrument?.problems?.flatMap(p => p.verificationErrors) || [])
        ];
        return sendProblems.length ? sendProblems : null;
    }

    getAccountStatusDisplayString(response: AdyenOnboardingData) {
        return response
            ? this.resources?.[`mc.adyenOnboarding.table.accountStatus.${response?.accountHolderData?.status}`]
            : '';
    }

    getAccountStatusInfoMessage(response: AdyenOnboardingData) {
        return response
            ? this.resources?.[`mc.adyenOnboarding.table.accountStatus.${response?.accountHolderData?.status}.info`]
            : '';
    }

    getPaymentStatusDisplayString(status: CapabilityDisplayStatus) {
        return this.resources?.[`mc.adyenOnboarding.table.paymentStatus.${status}`];
    }

    getPaymentStatusInfoMessage(status: CapabilityDisplayStatus) {
        return this.resources?.[`mc.adyenOnboarding.table.paymentStatus.${status}.info`];
    }

    getDeactivatedPaymentTypeInfo(type: string) {
        return this.resources?.[`mc.adyenOnboarding.table.paymentStatus.${type}.deactivated.info`];
    }

    gePaymentStatusErrorString(error: CapabilityProblemError) {
        return `${error.code} ${error.message}`;
    }

    isPaymentStatusActionRequired(status: CapabilityDisplayStatus) {
        return status === CapabilityDisplayStatus.ACTION_REQUIRED;
    }
}
