import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
    selector: '[phxInterceptLinks]'
})
export class InterceptLinksDirective {
    constructor(private router: Router, private elementRef: ElementRef) {}

    @Output()
    phxEvent = new EventEmitter<string>();

    @HostListener('click', ['$event'])
    public onClick($event) {
        // find the linkElement that was clicked
        const linkElement = ($event.target as HTMLElement).closest('a');

        // we only care about a tags with hrefs (e.g. not named anchors)
        if (linkElement?.getAttribute('href') == null) {
            return undefined;
        }

        // special handling for 'javascript:' links.
        // an event with the string value in phx() will be emitted
        // (e.g. "javascript:phx('openDialog')" will emit 'openDialog')
        // this is meant to be used as a signal to the embedding component to trigger some action
        const phxLink = linkElement.href.match(/javascript:phx\('(.+)'\)/);
        if (phxLink) {
            this.phxEvent.emit(phxLink[1]);
            // stop further processing
            return false;
        }

        // create a url object based on the linkElement's href
        const url = new URL(linkElement.href);

        // get the original supplied href value (not modified by the browser)
        const originalHrefValue = linkElement.getAttribute('href');

        // eject if the link has a download attribute
        if (linkElement.getAttribute('download') !== null) {
            return undefined;
        }

        // eject if the link should be opened in a new window/tab
        if (linkElement.target === '_blank') {
            return undefined;
        }

        // eject if link points to another domain
        // NOTE: on IE11 it sometimes happen that `URL.host` includes unnecessary default ports 443/80 and location.host does not
        if (url.host.replace(/:443|:80$/, '') !== location.host.replace(/:443|:80$/, '')) {
            return undefined;
        }

        // if you are still here, the router will take over and do the navigation
        if (originalHrefValue?.startsWith('#')) {
            // only fragment, so go to named anchor or element with the given id
            this.router.navigate([], { fragment: originalHrefValue.substring(1) });
        } else if (originalHrefValue?.startsWith('(')) {
            // we just want to open an aux/child route relative to the current route
            this.router.navigateByUrl(location.pathname + originalHrefValue);
        } else {
            this.router.navigateByUrl(url.pathname);
        }

        // stop other click processing
        return false;
    }
}
