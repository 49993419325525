import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ConfigurationService } from '../configuration/configuration.service';
import { LoggerService } from '../logging/logger.service';
import { ApiErrorCustomCodes } from './api-error';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
    constructor(private injector: Injector, private config: ConfigurationService) {}

    handleError(error: Error) {
        // ignore error if we already handled it (went through logger.error() already)
        if (error['isAlreadyHandled']) {
            return;
        }

        // the error handler is instantiated to early, so we need to get the logger service here via the injector
        const loggerService = this.injector.get(LoggerService);

        try {
            if (error?.name?.includes('ChunkLoadError')) {
                error['code'] = ApiErrorCustomCodes.ClientChunkLoadingError;
            }
        } catch (e) {}

        loggerService.error(error);
    }
}
