import { Component } from '@angular/core';

@Component({
    selector: 'phx-empty',
    templateUrl: './empty.component.html',
    styleUrls: ['./empty.component.scss']
})
export class EmptyComponent {
    constructor() {}
}
