<div class="container p-3">
    <h1>{{ resources?.['mc.adyenOnboarding.headline'] }}</h1>

    <ng-template #subTextComponentPlaceholder phxDynamicComponent></ng-template>

    <div *ngIf="!loading && hasError" class="text-center pt-3">
        <ngb-alert type="danger" [dismissible]="false">Fehler beim Laden der Daten.</ngb-alert>
    </div>

    <div class="text-center general-spinner my-4" *ngIf="loading">
        <div class="spinner-border text-danger" role="status"></div>
    </div>

    <ng-container *phxVar="adyenOnboardingData$ | async as response">
        <ng-container *ngIf="response?.adyenStatus !== adyenOnboardingStatus.UNREADY; else merchantNotReady">
            <button
                *ngIf="response?.adyenStatus === adyenOnboardingStatus.READY_FOR_CREATION"
                class="btn btn-lg fw-bold mmp-btn bg-primary"
                (click)="requestAdyenAccountCreation()"
                [disabled]="disableButton"
            >
                <div *ngIf="isButtonClickLoading" class="spinner-border text-light" role="status"></div>
                {{ resources?.['mc.adyenOnboarding.readyForCreation.button'] }}
            </button>
            <button
                *ngIf="response?.adyenStatus === adyenOnboardingStatus.READY_FOR_HOP"
                class="btn btn-lg fw-bold mmp-btn bg-primary"
                (click)="navigateToAdyenOnboardingPage()"
                [disabled]="disableButton"
            >
                <div *ngIf="isButtonClickLoading" class="spinner-border text-light" role="status"></div>
                {{ resources?.['mc.adyenOnboarding.goToHostedOnboardingPage.button'] }}
            </button>
        </ng-container>

        <table
            class="table table-bordered"
            [class.invisible]="loading || response?.adyenStatus !== adyenOnboardingStatus.READY_FOR_HOP"
        >
            <tbody>
                <tr>
                    <th scope="row">{{ resources?.['mc.adyenOnboarding.table.adyenAccountId'] }}</th>
                    <td>
                        {{ response?.accountHolderData?.id }}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ resources?.['mc.adyenOnboarding.table.balanceAccountId'] }}</th>
                    <td>
                        {{ response?.accountHolderData?.primaryBalanceAccount }}
                    </td>
                </tr>
                <tr>
                    <th scope="row">{{ resources?.['mc.adyenOnboarding.table.accountStatus'] }}</th>
                    <td>
                        <phx-expansion-panel>
                            <div
                                class="status fw-bold header-content"
                                [class]="adyenOnboardingStatusToCssClassMap.get(response?.accountHolderData?.status)"
                            >
                                {{ getAccountStatusDisplayString(response) }}
                            </div>
                            <div class="body-content card bg-light my-2">
                                <div class="card-body">
                                    {{ getAccountStatusInfoMessage(response) }}
                                </div>
                            </div>
                        </phx-expansion-panel>
                    </td>
                </tr>

                <tr *ngIf="compareFromBalanceCapabilities(response) as status">
                    <th scope="row">{{ resources?.['mc.adyenOnboarding.table.incomingPaymentStatus'] }}</th>
                    <td>
                        <ng-container
                            [ngTemplateOutlet]="paymentStatusExpansionPanel"
                            [ngTemplateOutletContext]="{ $implicit: status, response, type: 'incoming' }"
                        ></ng-container>
                    </td>
                </tr>
                <tr *ngIf="compareToBalanceCapabilities(response) as status">
                    <th scope="row">{{ resources?.['mc.adyenOnboarding.table.outgoingPaymentStatus'] }}</th>
                    <td>
                        <ng-container
                            [ngTemplateOutlet]="paymentStatusExpansionPanel"
                            [ngTemplateOutletContext]="{ $implicit: status, response, type: 'outgoing' }"
                        ></ng-container>
                    </td>
                </tr>
            </tbody>
        </table>

        <div [class.invisible]="loading || response?.adyenStatus !== adyenOnboardingStatus.READY_FOR_HOP">
            <ng-template #belowTableInfoComponentPlaceholder phxDynamicComponent></ng-template>
        </div>
    </ng-container>
</div>

<ng-template #isLoading>
    <div class="text-center">
        <div class="spinner-border text-danger" role="status"></div>
    </div>
</ng-template>

<ng-template #merchantNotReady>
    <div class="text-center pt-3">
        <ngb-alert type="primary" [dismissible]="false">{{ resources?.['mc.adyenOnboarding.info.message'] }}</ngb-alert>
    </div>
</ng-template>

<ng-template #paymentStatusExpansionPanel let-status let-response="response" let-type="type">
    <phx-expansion-panel>
        <div class="header-content">
            <span
                class="status fw-bold"
                [class]="adyenOnboardingStatusToCssClassMap.get(status)"
                [class.is-linked]="isPaymentStatusActionRequired(status)"
                (click)="navigateToAdyenOnboardingPageIfActionRequired($event, status)"
                >{{ getPaymentStatusDisplayString(status) }}</span
            >
            <span *ngIf="isPaymentStatusActionRequired(status)">
                {{resources?.['mc.adyenOnboarding.table.paymentStatus.actionRequired.headerInfo']}}</span
            >
        </div>
        <div class="body-content card bg-light my-2" *ngIf="status !== capabilityDisplayStatus.OK">
            <div class="card-body">
                <span *ngIf="status !== capabilityDisplayStatus.DEACTIVATED">
                    {{ getPaymentStatusInfoMessage(status) }}
                </span>

                <span *ngIf="status === capabilityDisplayStatus.DEACTIVATED && type">
                    {{ getDeactivatedPaymentTypeInfo(type) }}
                </span>

                <ul class="error-list" *ngIf="status === capabilityDisplayStatus.ACTION_REQUIRED">
                    <li *ngFor="let error of getSendErrors(response)">
                        {{ gePaymentStatusErrorString(error) }}
                    </li>
                </ul>
            </div>
        </div>
    </phx-expansion-panel>
</ng-template>
