import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration/configuration.service';

@Injectable({
    providedIn: 'root'
})
export class CmsPreviewModeService {
    forcePreview = false;
    ignoreRestrictions = false;

    constructor(private location: Location, private config: ConfigurationService) {
        const path = this.location.path();
        this.forcePreview =
            path.indexOf('phx-preview=1') > -1 || path.startsWith('/_preview') || !!this.config.get('forcePreview');
        this.ignoreRestrictions = this.forcePreview && path.indexOf('phx-ignoreRestrictions=1') > -1;
    }
}
