import { formatCurrency, formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ApiError,
    Config,
    ConfigurationService,
    ContentItem,
    DynamicComponentService,
    ResourceSet,
    ResourceSetService
} from '@phx/core';
import { DynamicComponentDirective } from '@phx/core-exports';
import { endOfDay, isAfter, isBefore, isDate, isFuture, isToday, startOfDay } from 'date-fns';
import { DateChangeEvent } from '../core-components/range-datepicker/range-datepicker.component';
import { BalanceAccount } from '../services/balance-account';
import { MerchantService } from '../services/merchant.service';
import { Transaction } from '../services/transaction';
import { formatIsoDate, formatLocalDate, parseLocalDate } from '../utils/date-utils';
import { SortEvent, SortableHeaderDirective } from '../utils/sortable-header.directive';
import { capitalizeFirstLetter, compare, getObjProperty } from '../utils/utils';

interface TransactionsQueryParams {
    since: string;
    until: string;
    limit: string;
    cursor?: string;
    id?: string;
}

@Component({
    selector: 'phx-transactions-component',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
    @ViewChild('introComponentPlaceholder', { read: DynamicComponentDirective, static: true })
    introComponentPlaceholder: DynamicComponentDirective;

    @ViewChild('notOnboardedInfoComponentPlaceholder', { read: DynamicComponentDirective, static: true })
    notOnboardedInfoComponentPlaceholder: DynamicComponentDirective;

    @ViewChild('balanceAccountsIntroComponentPlaceholder', { read: DynamicComponentDirective, static: true })
    balanceAccountsIntroComponentPlaceholder: DynamicComponentDirective;

    @ViewChild('balanceAccountsLegendComponentPlaceholder', { read: DynamicComponentDirective, static: true })
    balanceAccountsLegendComponentPlaceholder: DynamicComponentDirective;

    @ViewChild('transactionListingIntroComponentPlaceholder', { read: DynamicComponentDirective, static: true })
    transactionListingIntroComponentPlaceholder: DynamicComponentDirective;

    @ViewChild('transactionListingLegendComponentPlaceholder', { read: DynamicComponentDirective, static: true })
    transactionListingLegendComponentPlaceholder: DynamicComponentDirective;

    @ViewChildren(SortableHeaderDirective)
    transactionHeaders: QueryList<SortableHeaderDirective>;

    @ViewChild('transactionDetails')
    transactionDetailsEl: ElementRef<HTMLElement>;

    resources: ResourceSet = {};

    balanceAccountsLoading: boolean;
    balanceAccountsError: boolean;
    balanceAccounts: BalanceAccount[];

    transactionsLoading: boolean;
    transactionsError?: ApiError;
    transactionCreatedSince: Date;
    transactionCreatedUntil: Date;
    appliedTransactionCreatedSince: Date;
    appliedTransactionCreatedUntil: Date;
    transactionLimits = ['20', '50', '100'];
    transactionLimit = this.transactionLimits[0];
    appliedTransactionLimit: number;
    nextTransactionPageCursor?: string;
    prevTransactionPageCursor?: string;
    appliedTransactionPageCursor?: string;
    transactions: Transaction[];
    sortedTransactions: Transaction[];
    selectedTransaction?: Transaction;
    selectedTransactionEntries?: { key: string; value: string }[];

    private config: Config;

    get isOnboardedOrLoading(): boolean {
        return this.balanceAccountsLoading || (!this.balanceAccountsError && !!this.balanceAccounts?.length);
    }

    get areTransactionDatesValid(): boolean {
        const today = startOfDay(new Date());

        return (
            isDate(this.transactionCreatedSince) &&
            isDate(this.transactionCreatedUntil) &&
            !isAfter(this.transactionCreatedSince, today) &&
            !isBefore(this.transactionCreatedUntil, this.transactionCreatedSince)
        );
    }

    get transactionsLoadingErrorText(): string {
        const errorCode = this.transactionsError?.body?.code.replace(/^AY-/, '');

        return (this.resources[`mc.transactions.transactionListing.loadingFailed.code.${errorCode}.error`] ??
            this.resources['mc.transactions.transactionListing.loadingFailed.error']) as string;
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private merchantService: MerchantService,
        private resourceSetService: ResourceSetService,
        private dynamicComponentService: DynamicComponentService,
        private titleService: Title,
        configurationService: ConfigurationService
    ) {
        this.config = configurationService.getConfig();

        this.getBalanceAccounts();
    }

    ngOnInit() {
        this.getResourceSet();

        this.titleService.setTitle(this.route.snapshot.data?.['title']);

        this.route.queryParams.subscribe((queryParams: TransactionsQueryParams) => {
            if (!queryParams.since || !queryParams.until || !queryParams.limit) {
                // missing date range or limit, set to current date and limit
                const today = startOfDay(new Date());
                const newQueryParams: TransactionsQueryParams = {
                    since: queryParams.since ?? formatLocalDate(today),
                    until: queryParams.until ?? formatLocalDate(today),
                    limit: this.transactionLimit
                };

                this.navigateToQueryParams(newQueryParams);

                return;
            }

            if (this.isTransactionQueryParamChange(queryParams)) {
                // refresh transactions only if the relevant params changed,
                // not if just the selected transaction id was changed
                // (this would make the page jump while the transaction is scrolled to)
                this.appliedTransactionCreatedSince = this.transactionCreatedSince = parseLocalDate(queryParams.since);
                this.appliedTransactionCreatedUntil = this.transactionCreatedUntil = parseLocalDate(queryParams.until);
                this.appliedTransactionLimit = +queryParams.limit;
                this.appliedTransactionPageCursor = queryParams.cursor;

                this.getTransactions(queryParams.id);
            } else {
                this.selectTransaction(queryParams.id);
            }
        });
    }

    onTransactionDateChange(event: DateChangeEvent) {
        this.transactionCreatedSince = event.fromDate;
        this.transactionCreatedUntil = event.toDate;
    }

    onTransactionLimitChange(transactionLimit: string) {
        this.transactionLimit = transactionLimit;
    }

    submitTransactionParams() {
        const queryParams: TransactionsQueryParams = {
            since: formatLocalDate(this.transactionCreatedSince),
            until: formatLocalDate(this.transactionCreatedUntil),
            limit: this.transactionLimit,
            cursor: undefined,
            id: undefined
        };

        if (!this.isTransactionQueryParamChange(queryParams)) {
            // refresh transactions also if no param changed to get new transactions
            this.getTransactions();

            return;
        }

        this.navigateToQueryParams(queryParams);
    }

    goToNextTransactionPage() {
        this.navigateToQueryParams({
            cursor: this.nextTransactionPageCursor,
            id: undefined
        });
    }

    goToPrevTransactionPage() {
        this.navigateToQueryParams({
            cursor: this.prevTransactionPageCursor,
            id: undefined
        });
    }

    goToSelectedTransaction(transactionId: string) {
        if (transactionId === this.selectedTransaction?.id) {
            this.scrollToSelectedTransation();

            return;
        }

        this.navigateToQueryParams({
            id: transactionId
        });
    }

    onTransactionSort({ column, direction }: SortEvent) {
        // resetting other headers
        this.transactionHeaders.forEach(header => {
            if (header.phxSortable !== column) {
                header.phxDirection = '';
            }
        });

        // sort
        if (direction === '' || column === '') {
            this.sortedTransactions = this.transactions;
        } else {
            this.sortedTransactions = [...this.transactions].sort((a, b) => {
                const res = compare(getObjProperty(a, column), getObjProperty(b, column));
                return direction === 'asc' ? res : -res;
            });
        }
    }

    getMppOrderUrl(merchantOrderCode: string): string {
        // NOTE: this method should only be used for transactions of type "capture",
        // since only these are available as MPP order detail pages
        return `${this.config['mppHomeUrl']}/merchant/orders/orderDetails?orderID=${merchantOrderCode}`;
    }

    selectTransaction(transactionId: string) {
        this.selectedTransaction = this.transactions?.find(transaction => transaction.id === transactionId);

        if (!this.selectedTransaction) {
            return;
        }

        this.selectedTransactionEntries = Object.entries(this.selectedTransaction).map(([key, value]) => {
            if (['amount', 'instructedAmount'].includes(key)) {
                return {
                    key: capitalizeFirstLetter(key),
                    value: formatCurrency(value as number, 'de', '€', 'EUR')
                };
            }

            if (['createdAt', 'bookingDate', 'valueDate'].includes(key)) {
                return {
                    key: capitalizeFirstLetter(key),
                    value: formatDate(value as string, 'dd.MM.YYYY HH:mm', 'de')
                };
            }

            if (key === 'merchantOrderCode') {
                return {
                    key: capitalizeFirstLetter(key),
                    value: (value as string).toUpperCase()
                };
            }

            return { key: capitalizeFirstLetter(key), value: value as string };
        });

        this.scrollToSelectedTransation();
    }

    private getResourceSet() {
        this.resourceSetService.getSiteContent().subscribe(resources => {
            this.resources = resources;

            this.dynamicComponentService.renderComponent(
                this.resources['mc.transactions.intro'] as ContentItem,
                this.introComponentPlaceholder
            );

            this.dynamicComponentService.renderComponent(
                this.resources['mc.transactions.notOnboarded.info'] as ContentItem,
                this.notOnboardedInfoComponentPlaceholder
            );

            this.dynamicComponentService.renderComponent(
                this.resources['mc.transactions.balanceAccounts.intro'] as ContentItem,
                this.balanceAccountsIntroComponentPlaceholder
            );

            this.dynamicComponentService.renderComponent(
                this.resources['mc.transactions.balanceAccounts.legend'] as ContentItem,
                this.balanceAccountsLegendComponentPlaceholder
            );

            this.dynamicComponentService.renderComponent(
                this.resources['mc.transactions.transactionListing.intro'] as ContentItem,
                this.transactionListingIntroComponentPlaceholder
            );

            this.dynamicComponentService.renderComponent(
                this.resources['mc.transactions.transactionListing.legend'] as ContentItem,
                this.transactionListingLegendComponentPlaceholder
            );
        });
    }

    private getBalanceAccounts() {
        this.balanceAccountsLoading = true;
        this.balanceAccountsError = false;

        this.merchantService.getBalanceAccounts().subscribe({
            next: response => {
                this.balanceAccounts =
                    response?.balanceAccounts?.map(balanceAccount => ({
                        ...balanceAccount,
                        status: this.resources[
                            `mc.transactions.balanceAccounts.table.status.value.${balanceAccount.status}`
                        ] as string
                    })) || [];

                this.balanceAccountsLoading = false;
            },
            error: error => {
                this.balanceAccountsLoading = false;
                this.balanceAccountsError = true;
            }
        });
    }

    private getTransactions(selectedTransactionId?: string) {
        this.transactionsLoading = true;
        this.transactionsError = undefined;

        this.selectedTransaction = undefined;

        this.merchantService
            .getTransactions(
                this.appliedTransactionLimit,
                this.appliedTransactionCreatedSince
                    ? formatIsoDate(startOfDay(this.appliedTransactionCreatedSince))
                    : null,
                this.appliedTransactionCreatedUntil &&
                    !(isToday(this.appliedTransactionCreatedUntil) || isFuture(this.appliedTransactionCreatedUntil))
                    ? formatIsoDate(endOfDay(this.appliedTransactionCreatedUntil))
                    : null,
                this.appliedTransactionPageCursor,
                null
            )
            .subscribe({
                next: response => {
                    this.transactions =
                        response?.transactions?.filter(transaction => transaction.status !== 'pending') || [];
                    this.sortedTransactions = this.transactions;
                    this.nextTransactionPageCursor = response?.nextPageCursor;
                    this.prevTransactionPageCursor = response?.prevPageCursor;

                    this.selectTransaction(selectedTransactionId);

                    this.transactionsLoading = false;
                },
                error: (error: ApiError) => {
                    this.transactionsLoading = false;
                    this.transactionsError = error;
                }
            });
    }

    private navigateToQueryParams(queryParams: Partial<TransactionsQueryParams>) {
        this.router.navigate(['/transactions'], {
            queryParams,
            queryParamsHandling: 'merge'
        });
    }

    private isTransactionQueryParamChange(queryParams: TransactionsQueryParams): boolean {
        return (
            queryParams.since !== formatLocalDate(this.appliedTransactionCreatedSince) ||
            queryParams.until !== formatLocalDate(this.appliedTransactionCreatedUntil) ||
            queryParams.limit !== this.appliedTransactionLimit.toString() ||
            queryParams.cursor !== this.appliedTransactionPageCursor
        );
    }

    private scrollToSelectedTransation() {
        setTimeout(() => this.transactionDetailsEl?.nativeElement.scrollIntoView());
    }
}
