import { Inject, Injectable, Optional, TransferState, makeStateKey } from '@angular/core';
import { CookieService } from '../service';
import { PlatformService } from '../universal/platform.service';
import { Environment } from './environment';
import { ServerVars } from './server-vars';

@Injectable({
    providedIn: 'root'
})
export class ServerVarsService {
    version: string;

    constructor(
        @Inject('PHX_SERVER_VARS')
        @Optional()
        serverVars: ServerVars,
        private transferState: TransferState,
        private platform: PlatformService,
        private cookieService: CookieService
    ) {
        if (this.platform.isServer) {
            // running on the server. fill the server vars
            transferState.set(serverVarsStateKey, serverVars || null);
        }

        // parse version information
        const fullVersionString = this.get('VERSION');
        const buildRegex = /_([0-9\.]{5,}.*)$/;
        const buildMatch = fullVersionString?.match(buildRegex);
        this.version = buildMatch?.[1] || fullVersionString || undefined;
    }

    getEnvironment(): Environment {
        return this.get('ENV') || 'local';
    }

    getVersion(): string {
        return this.version;
    }

    get(key: string) {
        // get from config variables supplied on the node server rendering side
        const serverValue = this.transferState.get<ServerVars>(serverVarsStateKey, {})?.[key];
        if (serverValue) {
            return serverValue;
        }

        // get from config variables supplied at client startup via index.html
        if (this.platform.isBrowser && window[`shp_${key}`] !== `%${key}%`) {
            return window[`shp_${key}`];
        }

        // as fallback, try the cookie approach
        return this.cookieService.get(`X-SHP-${key}`);
    }

    getAll() {
        const serverVars = this.transferState.get<ServerVars | null>(serverVarsStateKey, null);
        if (serverVars) {
            return serverVars;
        }

        // try the cookie approach
        const cookiePrefix = 'X-SHP-';
        const allCookies = this.cookieService.getAll();
        return Object.keys(allCookies)
            .filter(cookieName => cookieName.startsWith(cookiePrefix))
            .reduce((newServerVars, cookieName) => {
                return { ...newServerVars, [cookieName.substring(cookiePrefix.length)]: allCookies[cookieName] };
            }, {}) as ServerVars;
    }
}

export const serverVarsStateKey = makeStateKey<ServerVars>('PHX_SERVER_VARS');
