import { Injectable } from '@angular/core';
import { PlatformService } from '../universal/platform.service';

@Injectable({ providedIn: 'root' })
export class CustomScriptService {
    private SCRIPT_ID_PREFIX = 'phx-cms-script-id-';

    constructor(private platform: PlatformService) {}

    removeScript(cmsId: string) {
        this.platform.document.querySelectorAll(`script#${this.SCRIPT_ID_PREFIX}${cmsId}`).forEach(e => {
            e.parentNode.removeChild(e);
        });
    }

    injectScript(javascriptCode: string, cmsId: string) {
        if (this.platform.isServer) {
            return;
        }

        const scriptNode = this.platform.document.createElement('script');
        const bodyNode = this.platform.document.querySelector('body');

        javascriptCode = `
if (typeof Zone !== 'undefined') {
    Zone.current.fork({
        name: 'phx-zone-${cmsId}',
        onHandleError: function(parentZoneDelegate, currentZone, targetZone, error){
            if (window['phxLogError']) {
                window['phxLogError'](error);
            } else {
                console.error(error);
            }
        }
        })
        .runGuarded(function(){\n\n${javascriptCode}\n});
}`;

        scriptNode.id = `${this.SCRIPT_ID_PREFIX}${cmsId}`;
        scriptNode.type = 'text/javascript';
        scriptNode.text = javascriptCode;

        bodyNode.appendChild(scriptNode);
    }
}
