import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, NgModuleRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
    BrowserOnlyServerService,
    ConfigurationService,
    ContentItemTypeMapService,
    CustomErrorOverridesService,
    ErrorHandlerService,
    GeneralErrorService,
    ServerService,
    SimpleGeneralErrorService
} from '@phx/core';
import { CookieModule } from 'ngx-cookie';
import { environment } from '../environments/environment';
import { AdyenOnboardingComponent } from './adyen-onboarding/adyen-onboarding.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { autologinAppInitializerFn } from './auth/autologin-initializer-factory';
import { LoginComponent } from './auth/components/login/login.component';
import { FullAuthNeededHttpInterceptorService } from './auth/services/full-auth-needed-http-interceptor.service';
import { AuthEffects } from './auth/store/auth.effects';
import { authReducer } from './auth/store/auth.reducer';
import { ExpansionPanelComponent } from './core-components/expansion-panel/expansion-panel.component';
import { InterceptLinksDirective } from './core-components/intercept-links/intercept-links.directive';
import { RangeDatepickerComponent } from './core-components/range-datepicker/range-datepicker.component';
import { TrustUnsafePipe } from './core-components/trust-unsafe/trust-unsafe.pipe';
import { VarDirective } from './core-components/var/var.directive';
import { CoreExportsModule } from './core-exports';
import { CoreModule } from './core/core.module';
import {
    contentfulBaseComponentMap,
    contentfulBaseMappedComponents
} from './dynamic-content/dynamic-content-content-item-type-map';
import { FallbackComponent } from './dynamic-content/fallback/fallback.component';
import { RawHtmlComponent } from './dynamic-content/raw-html/raw-html.component';
import { EmptyComponent } from './empty/empty.component';
import { KpisComponent } from './kpis/kpis.component';
import { MatomoTagManagerService } from './services/matomo-tag-manager.service';
import { MerchantCockpitCustomErrorOverridesService } from './services/merchant-cockpit-custom-error-overrides.service';
import { TransactionsComponent } from './transactions/transactions.component';
import { NgbGermanDateAdapter, NgbGermanDateParserFormatter } from './utils/date-utils';
import { SortableHeaderDirective } from './utils/sortable-header.directive';

registerLocaleData(localeDe);

@NgModule({
    declarations: [
        AppComponent,
        KpisComponent,
        TransactionsComponent,
        EmptyComponent,
        LoginComponent,
        VarDirective,
        InterceptLinksDirective,
        RawHtmlComponent,
        FallbackComponent,
        ...contentfulBaseMappedComponents,
        TrustUnsafePipe,
        SortableHeaderDirective,
        AdyenOnboardingComponent,
        ExpansionPanelComponent,
        RangeDatepickerComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CookieModule.withOptions(),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        StoreModule.forRoot(
            { auth: authReducer },
            {
                runtimeChecks: {
                    // needed because of https://github.com/ngrx/platform/issues/2404
                    strictStateImmutability: false
                }
            }
        ),
        EffectsModule.forRoot([AuthEffects]),
        StoreDevtoolsModule.instrument({
            name: 'Merchant Cockpit',
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
            serialize: true
        , connectInZone: true}),
        CoreModule,
        CoreExportsModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'de' },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService
        },
        {
            provide: ServerService,
            useClass: BrowserOnlyServerService
        },
        {
            provide: CustomErrorOverridesService,
            useClass: MerchantCockpitCustomErrorOverridesService
        },
        {
            provide: GeneralErrorService,
            useClass: SimpleGeneralErrorService
        },
        {
            provide: APP_INITIALIZER,
            // using app initializer for autologin so that autologin is not blocked by Guards and can be fired right away
            useFactory: autologinAppInitializerFn,
            multi: true,
            deps: [Store]
        },
        {
            provide: APP_INITIALIZER,
            useFactory(matomoTagManager: MatomoTagManagerService, configurationService: ConfigurationService) {
                return function initTracking() {
                    if (configurationService.get<boolean>('tracking.matomo.mtm.enable')) {
                        const mtmId = configurationService.get<string>('tracking.matomo.mtm.id');
                        const mtmUrlTemplate = configurationService.get<string>('tracking.matomo.mtm.url');

                        matomoTagManager.initQueue(mtmId, mtmUrlTemplate);
                        matomoTagManager.init();
                    }
                };
            },
            multi: true,
            deps: [MatomoTagManagerService, ConfigurationService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: FullAuthNeededHttpInterceptorService,
            multi: true
        },

        { provide: NgbDateAdapter, useClass: NgbGermanDateAdapter },
        { provide: NgbDateParserFormatter, useClass: NgbGermanDateParserFormatter }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(ngModule: NgModuleRef<AppModule>, contentItemTypeMapService: ContentItemTypeMapService) {
        // add dynamic components of this module to the global service, so they can be used application wide
        contentItemTypeMapService.addToMap(contentfulBaseComponentMap, ngModule);
    }
}
