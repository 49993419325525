import { Injectable } from '@angular/core';
import { ApiError, ApiResponse, ApiService, LoggerService } from '@phx/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthCredentials } from '../models/auth-credentials.model';
import { UserModel } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    initialUser: UserModel = null;

    constructor(private api: ApiService, private logger: LoggerService) {}

    login(credentials: AuthCredentials): Observable<UserModel> {
        const payload = {
            ...credentials
        };

        this.logger.debug('[AUTH] login triggered');
        return this.api.post<UserModel>('auth/login', undefined, payload, { withCredentials: true }).pipe(
            tap({
                next: response => this.logger.debug(`[AUTH] login ok, user ${response.uid} logged in`),
                error: error =>
                    this.logger.debug(`[AUTH] login failed for user ${credentials.username}`, ['error', error])
            })
        );
    }

    logout(): Observable<boolean> {
        return this.api.post<UserModel>('auth/logout', undefined, undefined, { withCredentials: true }).pipe(
            map(() => {
                this.logger.debug('[AUTH] logout successful');
                return true;
            }),
            catchError((error: ApiError) => {
                // ignore certain errors 401, 402
                if (error.code === 402 || error.code === 401) {
                    this.logger.debug('[AUTH] logout returned: ' + error.code);
                    return of(true);
                } else {
                    this.logger.debug('[AUTH] logout failed', ['error', error]);
                    return of(false);
                }
            })
        );
    }

    autoLogin(): Observable<ApiResponse<UserModel>> {
        // aka AutoLogin
        // auth service architecture: https://bitbucket.org/postecommerce/authentication-service/src/master/
        // we will know if we are logged in only if auth api calls are not 401 as we are getting the token info only via a JWT cookie and it should be HttpOnly
        // ask API for user details to check if we get any and are therefore logged in
        // if 200 then set userdata
        // if 401 then set as not logged in/null
        // this could happen at startup and will be done by SSR as well
        // OR: do not delay startup and check it after load? do we need the user info before rendering cms pages?
        // TODO: how to hydrate this to the store. trigger a hydrate action?

        this.logger.debug('[AUTH] autologin triggered');

        return this.api
            .get<ApiResponse<AutologinApiResponse | UserModel>>('auth/autologin', undefined, {
                withCredentials: true,
                observe: 'response'
            })
            .pipe(
                tap(response => {
                    if (response.status === 204) {
                        throw new Error('Autologin unsuccessful (anonymous user)');
                    }
                }),
                tap({
                    next: response =>
                        this.logger.debug(
                            `[AUTH] autologin ok, user ${
                                response?.data['uid'] || response?.data['user']['uid']
                            } logged in`
                        ),
                    error: error => this.logger.debug(`[AUTH] autologin failed`, ['error', error])
                }),
                map(response => ({
                    ...response,
                    data: (response.data as AutologinApiResponse).user || (response.data as UserModel)
                }))
            );
    }

    impersonate(impersonatedUserId: string): Observable<UserModel> {
        const payload = {
            impersonatedUserId
        };

        this.logger.debug('[AUTH] impersonate triggered');
        return this.api.post<UserModel>('auth/impersonate', undefined, payload, { withCredentials: true }).pipe(
            tap({
                next: response => this.logger.debug(`[AUTH] impersonate ok, user ${impersonatedUserId} logged in`),
                error: error =>
                    this.logger.debug(`[AUTH] impersonate failed for user ${impersonatedUserId}`, ['error', error])
            })
        );
    }
}

export interface AutologinApiResponse {
    user?: UserModel;
    deliveryCountry?: string;
}
