import { UserModel } from '../models/user.model';
import { AuthActions, AuthActionTypes } from './auth.actions';

export interface AuthState {
    user: UserModel | null;
    isSemiAuth: boolean;
    lastKnownAuthState: LastKnownAuthState;
    lastLoginType: LastLoginType;
    returnUrl: string;
    error: any;
    loading: boolean;
    loaded: boolean;
    impersonationHandled: boolean;
}

export enum LastKnownAuthState {
    Unknown,
    LoggedOut,
    SemiLoggedIn,
    FullyLoggedIn
}

export enum LastLoginType {
    Unknown,
    Manual,
    Auto
}

export const initialAuthState: AuthState = {
    user: undefined,
    isSemiAuth: undefined,
    lastKnownAuthState: LastKnownAuthState.Unknown,
    lastLoginType: LastLoginType.Unknown,
    returnUrl: undefined,
    error: null,
    loading: false,
    loaded: false,
    impersonationHandled: false
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.LOGIN:
        case AuthActionTypes.AUTOLOGIN:
        case AuthActionTypes.IMPERSONATE: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }

        case AuthActionTypes.LOGIN_SUCCEEDED: {
            return {
                ...state,
                user: action.payload.user,
                isSemiAuth: null,
                lastKnownAuthState: LastKnownAuthState.FullyLoggedIn,
                lastLoginType: LastLoginType.Manual,
                loaded: true,
                loading: false
            };
        }

        case AuthActionTypes.SETRETURNURL: {
            return {
                ...state,
                returnUrl: action.payload
            };
        }

        case AuthActionTypes.AUTOLOGIN_SUCCEEDED: {
            return {
                ...state,
                user: action.payload.user,
                lastKnownAuthState: action.payload.isSemiAuth
                    ? LastKnownAuthState.SemiLoggedIn
                    : LastKnownAuthState.FullyLoggedIn,
                lastLoginType: LastLoginType.Auto,
                isSemiAuth: action.payload.isSemiAuth,
                loaded: true,
                loading: false
            };
        }

        case AuthActionTypes.IMPERSONATE_SUCCEEDED: {
            return {
                ...state,
                user: action.payload.user,
                loaded: true,
                loading: false,
                impersonationHandled: true
            };
        }

        case AuthActionTypes.SKIP_IMPERSONATION: {
            return {
                ...state,
                impersonationHandled: true
            };
        }

        case AuthActionTypes.LOGIN_FAILED: {
            // do not change the user/loggedin state on failure,
            // as it can be semi auth before and if not user is null anyway
            return {
                ...state,
                error: action.payload,
                loaded: true,
                loading: false
            };
        }

        case AuthActionTypes.AUTOLOGIN_FAILED: {
            return {
                ...state,
                user: null,
                lastKnownAuthState: LastKnownAuthState.LoggedOut,
                loaded: true,
                loading: false
            };
        }

        case AuthActionTypes.IMPERSONATE_FAILED: {
            return {
                ...state,
                error: action.payload,
                loaded: true,
                loading: false,
                impersonationHandled: true
            };
        }

        case AuthActionTypes.AUTOLOGIN_CANCELLED: {
            return {
                ...state,
                loading: false
            };
        }

        case AuthActionTypes.LOGOUT: {
            return {
                ...state,
                loaded: true,
                loading: true
            };
        }

        case AuthActionTypes.INTERRUPTLOGIN:
        case AuthActionTypes.INTERRUPTLOGIN_ON_FIRST_ROUTE: {
            // payload: flag if 402 error (full auth required and user is semi auth)
            return {
                ...state,
                user: action.payload ? state.user : null,
                isSemiAuth: action.payload ? true : null,
                loaded: true,
                loading: false
            };
        }

        case AuthActionTypes.LOGOUT_SUCCEEDED: {
            return {
                ...state,
                user: null,
                isSemiAuth: null,
                loaded: true,
                loading: false,
                impersonationHandled: false
            };
        }

        case AuthActionTypes.LOGOUT_FAILED: {
            return {
                ...state,
                error: action.payload,
                loaded: true,
                loading: false
            };
        }

        default: {
            return state;
        }
    }
}
