import { NoopAnimationDriver, ɵWebAnimationsDriver } from '@angular/animations/browser';
import { isBrowser } from './browser-detection';
import { getCookie } from './simple-cookie';

export function PhxAnimationDriverFactory() {
    // Disable animations globally at runtime module when doing E2E
    // '@.disabled' does not work for material popups/dialogs
    if (getCookie('phx-noanim') === '1') {
        addDisableCssAnimationsStyles();
        return new NoopAnimationDriver();
    }

    return new ɵWebAnimationsDriver();
}

export function addDisableCssAnimationsStyles() {
    try {
        if (isBrowser) {
            document.head.insertAdjacentHTML(
                'beforeend',
                `<style>
                    *, ::before, ::after {
                        animation: initial !important;
                        transition: initial !important;
                    }
                </style>`
            );
        }
    } catch (e) {}
}
