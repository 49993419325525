import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Config, ConfigurationService, NavigationService, PhxRouterStateSnapshot } from '@phx/core';
import { Observable } from 'rxjs';
import { AuthState } from './auth/store/auth.reducer';
import { getAuthState } from './auth/store/auth.selectors';

@Component({
    selector: 'phx-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'merchant-cockpit';
    config: Config;
    routerState: PhxRouterStateSnapshot;
    navigationLinks: NavigationLink[] = [
        { url: '/kpis', name: 'KPIs' },
        { url: '/paymentMethods', name: 'Bezahlmethoden' },
        { url: '/transactions', name: 'Kontoübersicht & Transaktionen' }
    ];
    user$: Observable<AuthState>;

    constructor(
        private configurationService: ConfigurationService,
        private navigationService: NavigationService,
        private store: Store
    ) {
        this.config = this.configurationService.getConfig();

        this.navigationService.routerStateSnapshot$.subscribe(routerState => {
            this.routerState = routerState;
        });
    }

    ngOnInit() {
        this.user$ = this.store.select(getAuthState);
    }
}

interface NavigationLink {
    url: string;
    name: string;
}
