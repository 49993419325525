import { Injectable, NgZone } from '@angular/core';
import { LoggerService, PlatformService } from '@phx/core';

export enum MatomoServiceError {
    QUEUE_NOT_INITIATED = '[MATOMO] The queue is not yet initiated',
    ALREADY_INITIATED = '[MATOMO] Tracking is already initiated',
    INVALID_CONFIGURATION = '[MATOMO] Invalid configuration'
}

@Injectable({
    providedIn: 'root'
})
export class MatomoTagManagerService {
    private isSSR: boolean;
    private isQueueInitiated: boolean;
    private isInitiated: boolean;
    private mtmUrl: string;

    constructor(private logger: LoggerService, platform: PlatformService, private ngZone: NgZone) {
        this.isSSR = platform.isServer;
    }

    // init queue so data layers and events can be collected already before initialization
    initQueue(mtmId: string, mtmUrlTemplate: string) {
        if (this.isSSR) {
            return;
        }

        if (!mtmId) {
            this.logger.error(new Error(MatomoServiceError.INVALID_CONFIGURATION));
            return;
        }

        this.mtmUrl = mtmUrlTemplate.replace('%ID%', mtmId);

        // MTM queue
        window['_mtm'] = window['_mtm'] || [];

        window['_mtm'].push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });

        this.isQueueInitiated = true;
    }

    init() {
        if (this.isSSR) {
            return;
        }

        if (!this.isQueueInitiated) {
            this.logger.error(new Error(MatomoServiceError.QUEUE_NOT_INITIATED));
            return;
        }

        if (this.isInitiated) {
            this.logger.error(new Error(MatomoServiceError.ALREADY_INITIATED));
            return;
        }

        this.ngZone.runOutsideAngular(() => {
            this.createMatomoTagManagerScript();
        });

        this.isInitiated = true;
    }

    private createMatomoTagManagerScript() {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.async = true;
        script.src = this.mtmUrl;
        head.appendChild(script);
    }
}
