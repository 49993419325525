import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { ConfigurationService } from '../configuration/configuration.service';
import { ResourceSet, ResourceValue } from './content-item';
import { ContentService } from './content.service';
import { ResourceSetCacheService } from './resource-set-cache.service';

@Injectable({
    providedIn: 'root'
})
export class ResourceSetService {
    constructor(
        private contentService: ContentService,
        private configurationService: ConfigurationService,
        private resourceSetCacheService: ResourceSetCacheService
    ) {}

    getResourceSetById(id: string) {
        if (!this.resourceSetCacheService.cachedResourceSets[id]) {
            // not in cache yet, so load from content service and put in cache
            // NOTE: content service also puts embeddedly fetched resourceSets in the cache implicitly
            this.resourceSetCacheService.cachedResourceSets[id] = this.contentService.getById(id).pipe(
                map(contentItem => contentItem.fields?.['resources'] as ResourceSet),
                // don't trigger multiple requests if there are parallel requests to this resourceSet (most likely SiteContent)
                // refCount = false to keep the initial api response and do not redo the http call if subscribers drop to zero
                shareReplay({ bufferSize: 1, refCount: false })
            );
        }

        return this.resourceSetCacheService.cachedResourceSets[id];
    }

    getResourceSetByConfigKey(configKey: string) {
        return this.getResourceSetById(this.configurationService.get<string>(configKey));
    }

    getResource(resourceSetConfigKey: string, resourceKey: string) {
        return this.getResourceSetByConfigKey(resourceSetConfigKey).pipe(map(rs => rs[resourceKey]));
    }

    getSiteContent() {
        return this.getResourceSetByConfigKey('siteContentId');
    }

    getSiteContentResource<T extends ResourceValue>(resourceKey: string) {
        return this.getSiteContent().pipe(map(sc => sc[resourceKey] as T));
    }
}
