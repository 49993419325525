<div class="d-flex">
    <div>
        Datum ab:
        <div class="dp-hidden position-absolute">
            <input
                name="datepicker"
                class="form-control invisible"
                ngbDatepicker
                #datepicker="ngbDatepicker"
                [autoClose]="'outside'"
                (dateSelect)="onDateSelection($event)"
                [displayMonths]="2"
                [dayTemplate]="t"
                outsideDays="hidden"
                [startDate]="fromDate!"
                tabindex="-1"
            />
            <ng-template #t let-date let-focused="focused">
                <span
                    class="custom-day"
                    [class.focused]="focused"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null"
                >
                    {{ date.day }}
                </span>
            </ng-template>
        </div>
        <div class="input-group">
            <input
                #dpFromDate
                ngbDatepicker
                class="form-control"
                placeholder="TT.MM.JJJJ"
                name="dpFromDate"
                [value]="formatter.format(fromDate)"
                (input)="fromDate = validateInput(fromDate, dpFromDate.value); onDateChange()"
            />
            <button
                class="btn btn-outline-secondary bi bi-calendar3"
                (click)="datepicker.toggle()"
                type="button"
            ></button>
        </div>
    </div>
    <div class="ms-3">
        Datum bis:
        <div class="input-group">
            <input
                #dpToDate
                ngbDatepicker
                class="form-control"
                placeholder="TT.MM.JJJJ"
                name="dpToDate"
                [value]="formatter.format(toDate)"
                (input)="toDate = validateInput(toDate, dpToDate.value); onDateChange()"
            />
            <button
                class="btn btn-outline-secondary bi bi-calendar3"
                (click)="datepicker.toggle()"
                type="button"
            ></button>
        </div>
    </div>
</div>
