import { Document } from '@contentful/rich-text-types';
import { NewsletterStatus } from '../models/newsletter.model';

export interface ContentItem {
    metadata: ContentItemMetadata;
    fields: ContentItemFieldCollection;
    data?: any;
}

export interface TextContentItem extends ContentItem {
    fields: { ['text']: RichTextDocument };
}

export interface ContentItemFieldCollection {
    [name: string]: ContentItemField;
}

export type ContentItemField =
    | string
    | number
    | boolean
    | Date
    | ContentItem
    | ContentItem[]
    | ContentAsset
    | ContentAsset[]
    | ContentImageSet
    | RichTextDocument
    | ContentLink
    | ContentLink[]
    | ResourceSet
    | RawResourceSet
    | Record<string, any>
    | any[];

// Fields
export interface ResourceSet {
    [key: string]: ResourceValue;
}

export interface RawResourceSet {
    id: string;
    entries: ResourceSet;
    nestedResourceSets: RawResourceSet[];
}

export type ResourceValue = string | number | boolean | ContentItem | ContentAsset | Record<string, unknown> | any[];

export type ContentImageSet = Record<string, ContentAsset>;

export interface ContentAsset {
    title: string;
    description: string;
    file: {
        mimeType: string;
        details: any;
        name: string;
        url: string;
    };
}

export interface ContentAssetResponsive {
    default: ContentAsset;
    mobile: ContentAsset;
    tablet: ContentAsset;
    desktop: ContentAsset;
}

export type RichTextDocument = Document;

export enum ContentLinkType {
    URL = 'URL',
    EXTERNAL_URL = 'EXTERNAL_URL',
    PAGE = 'PAGE',
    CATEGORY = 'CATEGORY',
    PRODUCT = 'PRODUCT',
    ASSET = 'ASSET'
}

export interface ContentLink {
    id: string;
    name: string;
    title?: string;
    url: string;
    type: ContentLinkType;
    openInNewWindow: boolean;
    buttonAppearance?: 'low' | 'medium' | 'high';
    pagePreview?: ContentLinkPagePreview;
}

export interface ContentLinkPagePreview {
    title: string;
    text?: RichTextDocument;
    image?: ContentAssetResponsive;
}

// Meta data
export interface ContentItemMetadata {
    type: string;
    id: string;
    name?: string;
    restrictions?: ContentItemRestrictions;
}

export interface ContentItemRestrictions {
    device?: ContentItemDeviceRestriction;
    schedule?: ContentItemScheduleRestriction;
    newsletter?: ContentItemNewsletterRestriction;
    directMailing?: ContentItemDirectMailingRestriction;
    product?: ContentItemProductRestriction;
    category?: ContentItemCategoryRestriction;
}

export interface ContentItemDeviceRestriction {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
}

export interface ContentItemScheduleRestriction {
    dateOnline: Date;
    dateOffline: Date;
}

export interface ContentItemNewsletterRestriction {
    hiddenStatuses: NewsletterStatus[];
}

export interface ContentItemDirectMailingRestriction {
    hiddenWhenDeclined: boolean;
}

export interface ContentItemProductRestriction {
    whitelistedProducts: string[];
    blacklistedProducts: string[];
}

export interface ContentItemCategoryRestriction {
    whitelistedCategories: string[];
    blacklistedCategories: string[];
}
