export const getCircularReplacer = () => {
    const seen = [];
    return (key: any, value: any) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.indexOf(value) !== -1) {
                return;
            }
            seen.push(value);
        }

        if (value instanceof Error) {
            const error = {};

            Object.getOwnPropertyNames(value).forEach(errorKey => {
                error[errorKey] = value[errorKey];
            });

            return error;
        }

        return value;
    };
};
