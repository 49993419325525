import { LogLevel } from '../logging/log';

export class ApiError<T = ApiErrorBody> extends Error {
    public date = new Date();
    public ignore = false;
    public hide = false;
    public isAlreadyHandled = false;
    public overrideLogLevel: LogLevel;

    constructor(public code: number, public body: T, public id: string, ...params: string[]) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error['captureStackTrace']) {
            Error['captureStackTrace'](this, ApiError);
        }
    }
}

export const enum ApiErrorCustomCodes {
    ClientNetworkError = 600,
    ClientTimeoutError = 601,
    ClientUnhandledErrorTypeError = 602,
    ClientErrorHandlingError = 603,
    ClientChunkLoadingError = 604
}

export interface ApiErrorBody {
    code: string;
    message: string;
    additionalMessages?: string[];
}
