import { Action } from '@ngrx/store';
import { AuthCredentials } from '../models/auth-credentials.model';
import { UserModel } from '../models/user.model';

export enum AuthActionTypes {
    SETRETURNURL = '[Login Page] Set ReturnUrl',
    INTERRUPTLOGIN = '[Auth] Interrupt Login',
    INTERRUPTLOGIN_ON_FIRST_ROUTE = '[Auth] Interrupt Login On First Route',
    INIT_LOGIN = '[Login Page] Init Login',
    INIT_SEMIAUTHLOGIN = '[Login Page] Init Semi Auth Login',
    LOGIN = '[Login Page] Login',
    LOGIN_SUCCEEDED = '[Auth API] Login Succeeded',
    LOGIN_FAILED = '[Auth API] Login Failed',
    LOGOUT = '[Auth] Logout',
    LOGOUT_SUCCEEDED = '[Auth API] Logout Succeeded',
    LOGOUT_FAILED = '[Auth API] Logout Failed',
    AUTOLOGIN = '[Auth] AutoLogin',
    AUTOLOGIN_SUCCEEDED = '[Auth API] AutoLogin Succeeded',
    AUTOLOGIN_FAILED = '[Auth API] AutoLogin Failed',
    AUTOLOGIN_CANCELLED = '[Auth API] AutoLogin Cancelled',
    IMPERSONATE = '[Auth] Impersonate',
    IMPERSONATE_SUCCEEDED = '[Auth API] Impersonate Succeeded',
    IMPERSONATE_FAILED = '[Auth API] Impersonate Failed',
    SKIP_IMPERSONATION = '[Auth] Skip Impersonation'
}

export class InitLogin implements Action {
    readonly type = AuthActionTypes.INIT_LOGIN;
}

export class InitSemiAuthLogin implements Action {
    readonly type = AuthActionTypes.INIT_SEMIAUTHLOGIN;

    // payload: email to use
    constructor(public payload: string) {}
}

export class SetReturnUrl implements Action {
    readonly type = AuthActionTypes.SETRETURNURL;

    constructor(public payload: string) {}
}

export class InterruptLogin implements Action {
    readonly type = AuthActionTypes.INTERRUPTLOGIN;

    constructor(public payload: boolean) {}
}

export class InterruptLoginOnFirstRoute implements Action {
    readonly type = AuthActionTypes.INTERRUPTLOGIN_ON_FIRST_ROUTE;

    constructor(public payload: boolean) {}
}

export class Login implements Action {
    readonly type = AuthActionTypes.LOGIN;

    constructor(public credentials: AuthCredentials) {}
}

export class LoginSucceeded implements Action {
    readonly type = AuthActionTypes.LOGIN_SUCCEEDED;

    constructor(public payload: { user: UserModel; withMigration: boolean }) {}
}

export class LoginFailed implements Action {
    readonly type = AuthActionTypes.LOGIN_FAILED;

    constructor(public payload: any) {}
}

export class AutoLogin implements Action {
    readonly type = AuthActionTypes.AUTOLOGIN;

    // force doing an autologin even if the authstate is already loaded
    constructor(public payload: boolean = false) {}
}

export class AutoLoginSucceeded implements Action {
    readonly type = AuthActionTypes.AUTOLOGIN_SUCCEEDED;

    constructor(public payload: { user: UserModel; isSemiAuth: boolean }) {}
}

export class AutoLoginFailed implements Action {
    readonly type = AuthActionTypes.AUTOLOGIN_FAILED;

    constructor(public payload: any) {}
}

export class AutoLoginCancelled implements Action {
    readonly type = AuthActionTypes.AUTOLOGIN_CANCELLED;
}

export class Logout implements Action {
    readonly type = AuthActionTypes.LOGOUT;

    constructor(public targetPath?: string) {}
}

export class LogoutSucceeded implements Action {
    readonly type = AuthActionTypes.LOGOUT_SUCCEEDED;

    constructor(public targetPath?: string) {}
}

export class LogoutFailed implements Action {
    readonly type = AuthActionTypes.LOGOUT_FAILED;

    constructor(public payload: any) {}
}

export class Impersonate implements Action {
    readonly type = AuthActionTypes.IMPERSONATE;

    constructor(public impersonatedUserId: string) {}
}

export class ImpersonateSucceeded implements Action {
    readonly type = AuthActionTypes.IMPERSONATE_SUCCEEDED;

    constructor(public payload: { user: UserModel }) {}
}

export class ImpersonateFailed implements Action {
    readonly type = AuthActionTypes.IMPERSONATE_FAILED;

    constructor(public payload: any) {}
}

export class SkipImpersonation implements Action {
    readonly type = AuthActionTypes.SKIP_IMPERSONATION;
}

export type AuthActions =
    | SetReturnUrl
    | InterruptLogin
    | InterruptLoginOnFirstRoute
    | InitLogin
    | InitSemiAuthLogin
    | Login
    | LoginSucceeded
    | LoginFailed
    | Logout
    | LogoutSucceeded
    | LogoutFailed
    | AutoLogin
    | AutoLoginSucceeded
    | AutoLoginFailed
    | AutoLoginCancelled
    | Impersonate
    | ImpersonateSucceeded
    | ImpersonateFailed
    | SkipImpersonation;
