import { Injectable, NgModule, NgModuleRef } from '@angular/core';

export interface ComponentInfo {
    component: any;
    ngModule: NgModuleRef<NgModule>;
}
export type ContentItemTypeMap = Map<string, ComponentInfo>;

@Injectable({
    providedIn: 'root'
})
export class ContentItemTypeMapService {
    private map: ContentItemTypeMap = new Map<string, ComponentInfo>([]);

    getMap() {
        return this.map;
    }

    addToMap(newMapEntries: ContentItemTypeMap, ngModule: NgModuleRef<NgModule>) {
        // assign corresponding ngModule for components
        const finalNewMapEntries = new Map<string, ComponentInfo>() as ContentItemTypeMap;
        Array.from(newMapEntries).forEach(c => {
            finalNewMapEntries.set(c[0], { ...c[1], ngModule });
        });

        // add to global map
        this.map = new Map<string, ComponentInfo>([
            ...Array.from(this.map),
            ...Array.from(finalNewMapEntries)
        ]) as ContentItemTypeMap;
    }
}
