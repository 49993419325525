import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Block, BLOCKS, Inline, Text } from '@contentful/rich-text-types';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { ApiError } from '../error-handling/api-error';
import { GeneralErrorService } from '../error-handling/general-error.service';
import { LoggerService } from '../logging/logger.service';
import { ImageFormatSupportService } from '../service';
import { PlatformService } from '../universal/platform.service';
import { CmsPreviewModeService } from './cms-preview-mode.service';
import { ContentItem, RawResourceSet } from './content-item';
import { CustomPages } from './custom-pages';
import { RedirectConfig } from './redirect-config';
import { ResourceSetCacheService } from './resource-set-cache.service';

export interface ContentCache {
    [id: string]: ContentCacheEntry;
}

export interface ContentCacheEntry {
    id: string;
    lastFetchedAt: number;
    apiResponse: Observable<ContentServiceApiResponse>;
    error?: ApiError;
}

export interface ContentServiceApiResponse {
    entries: ContentItem[];
    redirect?: RedirectConfig;
}

@Injectable({
    providedIn: 'root'
})
export class ContentService {
    cache: ContentCache;
    customPages: CustomPages;
    contentfulImageApiParams = '';
    contentfulImageApiUseWebpIfPossible = false;
    contentfulImageApiUseAvifIfPossible = false;
    contentMemoryCacheInSeconds = 0;

    private getContentfulImageApiParams() {
        if (this.contentfulImageApiUseAvifIfPossible && this.imageFormatSupportService.isAvifSupported) {
            return '?fm=avif';
        }

        if (this.contentfulImageApiUseWebpIfPossible && this.imageFormatSupportService.isWebpSupported) {
            return '?fm=webp';
        }

        return this.contentfulImageApiParams;
    }

    constructor(
        private api: ApiService,
        private logger: LoggerService,
        private router: Router,
        private configService: ConfigurationService,
        private previewMode: CmsPreviewModeService,
        private platform: PlatformService,
        private resourceSetCacheService: ResourceSetCacheService,
        private generalErrorService: GeneralErrorService,
        private imageFormatSupportService: ImageFormatSupportService
    ) {
        this.cache = {};
        this.contentfulImageApiParams = this.configService.get<string>('contentfulImageApiParams');
        this.contentfulImageApiUseWebpIfPossible = this.configService.get<boolean>(
            'contentfulImageApiUseWebpIfPossible'
        );
        this.contentfulImageApiUseAvifIfPossible = this.configService.get<boolean>(
            'contentfulImageApiUseAvifIfPossible'
        );
        this.contentMemoryCacheInSeconds = this.configService.get<number>('contentMemoryCacheInSeconds');
    }

    getById(id: string): Observable<ContentItem> {
        const query = { 'sys.id': id };
        return this.getByQuery(query).pipe(
            map(response => {
                if (response.entries?.length > 0) {
                    return response.entries[0];
                }

                throw new Error(`Contentful item with id '${id}' not found.`);
            })
        );
    }

    getByQuery(query: Record<string, unknown>): Observable<ContentServiceApiResponse> {
        return this.queryApi(query);
    }

    getByConfigKey(configKey: string): Observable<ContentItem> {
        const id = this.configService.get<string>(configKey);
        return this.getById(id);
    }

    getCustomPages() {
        const forcePreview = this.previewMode.forcePreview;

        return this.api
            .get<CustomPages>('content/customPages', {
                ...(forcePreview && { forcePreview })
            })
            .pipe(
                catchError(error => {
                    this.logger.debug(`[CONTENT SERVICE] CustomPages API call (forcePreview=${forcePreview}) failed`, [
                        'error',
                        error
                    ]);

                    return of({
                        list: [],
                        redirects: {}
                    });
                })
            );
    }

    private queryApi(query: Record<string, unknown>, ignoreError = false): Observable<ContentServiceApiResponse> {
        const key = JSON.stringify(query);

        const cachedResponse$ = this.readCache(key);
        if (cachedResponse$) {
            return cachedResponse$;
        }

        if (this.previewMode.forcePreview) {
            query = { ...query, forcePreview: 'true' };
        }

        const apiResponse$ = this.fetchFromApi(query, this.previewMode.forcePreview).pipe(
            catchError(error => {
                this.logger.debug(`[CONTENT SERVICE] API call (url: '${JSON.stringify(query)}') failed`, [
                    'error',
                    error
                ]);

                if (ignoreError) {
                    return undefined;
                }

                if (
                    !!this.configService.get('redirectOnError') &&
                    !this.platform.document.location.href.endsWith('basic-auth-helper')
                ) {
                    // do not redirect when on the basic-auth-helper page to avoid possible redirect cycles between general-error and the helper page
                    this.router.navigateByUrl('/general-error', {
                        state: {
                            error
                        }
                    });
                    return EMPTY;
                } else {
                    this.generalErrorService.informUser(error);
                    return EMPTY;
                }
            }),
            tap(response => {
                if (!response) {
                    return;
                }

                // map ContentItems if needed (e.g. resourceSets)
                response.entries?.forEach(contentItem => {
                    this.transformContentItem(contentItem);
                });
            }),
            // don't trigger multiple requests if there are parallel requests with this content query (e.g. `deliveryInfoDialogContentId` on PDPs)
            // refCount = true to clear if not used anymore
            shareReplay({ bufferSize: 1, refCount: true })
        );

        // store in cache (if enabled) and return response
        return this.writeCache(key, apiResponse$);
    }

    private fetchFromApi(query: Record<string, unknown>, forcePreview?: boolean) {
        if (query['fields.parentPage.sys.id'] && query['content_type'] === 'page') {
            // child pages
            return this.api.get<ContentServiceApiResponse>('content/childPageEntries', {
                parentPageId: query['fields.parentPage.sys.id'],
                ...(forcePreview && { forcePreview })
            });
        }

        if (query['content_type'] === 'page') {
            // page entry
            return this.api.get<ContentServiceApiResponse>('content/pageEntries', {
                pageQuery: query['fields.queryIds[in]'],
                ...(forcePreview && { forcePreview })
            });
        }

        if (query['sys.id']) {
            // any entry by ID
            return this.api.get<ContentServiceApiResponse>('content/entriesById', {
                entryId: query['sys.id'],
                ...(forcePreview && { forcePreview })
            });
        }

        // unknown query type
        throw new Error(`Unknown content service query type: ${JSON.stringify(query)}`);
    }

    private transformContentItem(contentItem: ContentItem) {
        this.updateImagesWithContentfulImageApiParams(contentItem.fields);

        for (const key of Object.keys(contentItem.fields)) {
            if (Array.isArray(contentItem.fields[key])) {
                contentItem.fields[key] = (contentItem.fields[key] as []).map(f => this.mapField(f));
            } else {
                contentItem.fields[key] = this.mapField(contentItem.fields[key]);
            }
        }
    }

    private updateImagesWithContentfulImageApiParams(obj: any) {
        if (obj instanceof Object) {
            // check if it is an asset
            if (obj.details && obj.mimeType && obj.url) {
                if (
                    obj.url.startsWith('//images.ctfassets.net') &&
                    !obj.mimeType.startsWith('image/svg') &&
                    !obj.mimeType.startsWith('image/gif') &&
                    !obj.url.endsWith(this.getContentfulImageApiParams())
                ) {
                    // apply imageApi params
                    obj.url = obj.url + this.getContentfulImageApiParams();
                }
            }

            // keep traversing
            Object.values(obj).forEach(ov => {
                this.updateImagesWithContentfulImageApiParams(ov);
            });
        }
    }

    private mapField(field: any) {
        if (field['entries'] != null && field['nestedResourceSets'] != null) {
            field = this.flatResources(field as RawResourceSet);
        }

        if (field.fields && field.metadata) {
            // content item again
            this.transformContentItem(field);
        }

        if (field.nodeType === BLOCKS.DOCUMENT) {
            this.mapRichTextNode(field);
        }

        return field;
    }

    private mapRichTextNode(node: Block | Inline | Text) {
        node['content']?.map((childNode: Block | Inline | Text) => {
            this.mapRichTextNode(childNode);
        });

        if (node.data.target) {
            // map node data (e.g. flatten resources)
            this.mapField(node.data.target);
        }
    }

    private flatResources(resourceSet: RawResourceSet) {
        let flattenedEntries = { ...resourceSet.entries };

        resourceSet.nestedResourceSets.forEach(nestedResourceSet => {
            flattenedEntries = { ...flattenedEntries, ...this.flatResources(nestedResourceSet) };
        });

        this.resourceSetCacheService.addResourceSet(resourceSet.id, of(flattenedEntries));

        // check resources for nested contentItems again (e.g. resource sets in feature components)
        Object.values(flattenedEntries).forEach((resourceEntry: ContentItem) => {
            if (Array.isArray(resourceEntry)) {
                // EntryList resource
                resourceEntry.forEach(resourceEntryItem => {
                    if (resourceEntryItem.fields && resourceEntryItem.metadata) {
                        // content item again
                        this.transformContentItem(resourceEntryItem);
                    }
                });
            } else if (resourceEntry.fields && resourceEntry.metadata) {
                // content item again
                this.transformContentItem(resourceEntry);
            }
        });

        return flattenedEntries;
    }

    // Cache helpers
    private writeCache(
        key: string,
        apiResponse$: Observable<ContentServiceApiResponse>
    ): Observable<ContentServiceApiResponse> {
        if (this.contentMemoryCacheInSeconds > 0) {
            this.cache[key] = {
                apiResponse: apiResponse$,
                id: key,
                lastFetchedAt: Date.now()
            };
        }

        return apiResponse$;
    }

    private readCache(key: string): Observable<ContentServiceApiResponse> {
        if (this.contentMemoryCacheInSeconds > 0) {
            const cacheEntry = this.cache[key];
            if (cacheEntry) {
                if (
                    this.contentMemoryCacheInSeconds === 0 ||
                    (Date.now() - cacheEntry.lastFetchedAt) / 1000 > this.contentMemoryCacheInSeconds
                ) {
                    // evict cached content
                    delete this.cache[key];
                } else {
                    return cacheEntry.apiResponse;
                }
            }
        }

        return undefined;
    }
}
