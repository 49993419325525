// Ease the use of universal server/browser functionality
// taken from https://github.com/TrilonIO/ng-universal/blob/master/libraries/ng-universal/src/lib/services/platform.service.ts

import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {
    constructor(
        @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
        @Inject(DOCUMENT) private doc: Document
    ) {}

    public get document() {
        return this.doc;
    }

    public get isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    public get isServer(): boolean {
        return isPlatformServer(this.platformId);
    }
}
