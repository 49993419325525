<div
    (click)="hasBodyContent ? (isCollapsed = !isCollapsed) : isCollapsed"
    [attr.aria-expanded]="!isCollapsed"
    class="header"
>
    <ng-content select=".header-content"></ng-content>
    <i class="bi" [ngClass]="isCollapsed ? 'bi-chevron-down' : 'bi-chevron-up'" [class.disabled]="!hasBodyContent"></i>
</div>

<div #bodyContent #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="body">
    <ng-content select=".body-content"></ng-content>
</div>
