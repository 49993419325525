import { Dictionary } from '../utils/dictionary.type';

// alias to query and if it is a main (mutually exclusive) breakpoint
export type BreakpointConfig = Dictionary<[string, boolean]>;

export const BREAKPOINTS: BreakpointConfig = {
    xs: ['screen and (min-width: 0px) and (max-width: 767.9px)', true],
    sm: ['screen and (min-width: 768px) and (max-width: 1023.9px)', true],
    md: ['screen and (min-width: 1024px) and (max-width: 1279.9px)', true],
    lg: ['screen and (min-width: 1280px) and (max-width: 1919.9px)', true],
    xl: ['screen and (min-width: 1920px) and (max-width: 4999.9px)', true],
    'xs-liquid': ['screen and (min-width: 0px) and (max-width: 599.9px)', false],
    'xs-fixed': ['screen and (min-width: 600px) and (max-width: 767.9px)', false],
    'lt-xs-fixed': ['screen and (max-width: 599.9px)', false],
    'lt-sm': ['screen and (max-width: 767.9px)', false],
    'lt-md': ['screen and (max-width: 1023.9px)', false],
    'lt-lg': ['screen and (max-width: 1279.9px)', false],
    'lt-xl': ['screen and (max-width: 1919.9px)', false],
    'gt-xs-liquid': ['screen and (min-width: 600px)', false],
    'gt-xs': ['screen and (min-width: 768px)', false],
    'gt-sm': ['screen and (min-width: 1024px)', false],
    'gt-md': ['screen and (min-width: 1280px)', false],
    'gt-lg': ['screen and (min-width: 1920px)', false],
    'sm-md': ['screen and (min-width: 768px) and (max-width: 1279.9px)', false]
};
