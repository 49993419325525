import { Location } from '@angular/common';
import { ApplicationRef, Injectable, Injector, NgZone } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, interval, of } from 'rxjs';
import { delay, filter, map, shareReplay, startWith, take, tap } from 'rxjs/operators';
import 'zone.js/plugins/task-tracking';
import { ConfigurationService } from '../configuration/configuration.service';
import { ApiErrorCustomCodes } from '../error-handling/api-error';
import { GeneralErrorService } from '../error-handling/general-error.service';
import { LoggerService } from '../logging/logger.service';
import {
    FORCE_REFRESH_QUERYPARAM_KEY,
    FORCE_RELOAD_QUERYPARAM_KEY,
    FORCE_REUSE_QUERYPARAM_KEY
} from '../router/phx-route-reuse-strategy';
import { PhxRouterStateSnapshot } from '../router/phx-router-state-snapshot';
import { serializeRouterState } from '../router/serialize-router-state';
import { objectFromParamMap } from '../router/utils';
import { leaveNgZone } from '../rxjs-zone-scheduler/rx-ng-zone-scheduler';
import { PlatformService } from '../universal/platform.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    appInitiated = false;
    // when the navigation to the first initial page was intercepted by a login dialog, the resulting loaded page, after login,
    // is no longer the first route. in that case, and should we need it in the future, a new property has to be implemented,
    // that tracks this case and signals, that the loaded page was actually attempted to load as first route, despite the login intercept
    isFirstRoute = true;
    routerStateSnapshot$: Observable<PhxRouterStateSnapshot>;
    routerStateSnapshot: PhxRouterStateSnapshot;
    startupTimestamp = Date.now();
    forcedPageRefreshIntervalInSeconds = 0;
    isStabilizationLogEnabled = false;
    stabilizationLogWaitInMs: number;
    stabilizationLogTimeoutId: any;

    // custom stabilization observable (ignoring setTimeout and setInterval tasks)
    customStabilizationCheckInProgress: boolean;
    customStabilizationCheckIntervalInMs: number; // 0 = disabled, so only rely on Angular isStable
    isStable$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    get isAngularStable$() {
        return this.injector.get(ApplicationRef).isStable;
    }

    constructor(
        private location: Location,
        private router: Router,
        private injector: Injector,
        private platform: PlatformService,
        private config: ConfigurationService,
        private ngZone: NgZone,
        private log: LoggerService
    ) {
        this.forcedPageRefreshIntervalInSeconds = this.config.get<number>('forcedPageRefreshIntervalInSeconds');
        this.isStabilizationLogEnabled = this.config.get<boolean>('stabilizationLog.enabled', true) || false;
        this.stabilizationLogWaitInMs = this.config.get<number>('stabilizationLog.waitInMs', true) || 3000;
        this.customStabilizationCheckIntervalInMs =
            this.config.get<number>('customStabilizationCheckIntervalInMs', true) || 0;
    }

    init() {
        this.appInitiated = true;
        let previousUrl: string = null;

        this.routerStateSnapshot$ = this.router.events.pipe(
            tap(e => {
                if (e instanceof NavigationStart) {
                    // if we want to do a route change after the page has loaded, it is not handling the first route anymore
                    // and as the resolvers/guards can have errors, we need to reset the first route flag here
                    // (e.g. needed for certain interrupt login scenarios)
                    if (e.id > 1 && this.isFirstRoute) {
                        this.isFirstRoute = false;
                    }

                    // Reset custom stable flag on new route
                    this.isStable$.next(false);

                    // Reset stabilization log timeout
                    if (this.isStabilizationLogEnabled && this.stabilizationLogTimeoutId) {
                        clearTimeout(this.stabilizationLogTimeoutId);
                        this.stabilizationLogTimeoutId = null;
                    }
                }

                // catch error when lazy-loading Angular modules and try to fix the situation by doing a full page refresh to the requested URL.
                // this normally happens when the user has an old version of storefront already loaded and navigates to routes that require additional JS modules,
                // but meanwhile we deployed a new version, so the bundles have different hashes and the old ones that the app requested are of course not on the server anymore
                if (e instanceof NavigationError && e.error?.name?.includes('ChunkLoadError')) {
                    const loggerService = this.injector.get(LoggerService);
                    e.error.code = ApiErrorCustomCodes.ClientChunkLoadingError;

                    // if already tried workaround, just inform user. avoid endless error loop
                    if (e.url.includes(`${FORCE_RELOAD_QUERYPARAM_KEY}=1`)) {
                        // the page refresh (to the target route) did not help, inform user and go back to home
                        const generalErrorService = this.injector.get(GeneralErrorService);
                        generalErrorService?.informUser(e.error);
                        return;
                    }

                    // reload browser to target url
                    loggerService?.warn(
                        `[NAV] Error lazy-loading Angular module for route '${e.url}' (e.g. outdated version): ${e.error?.message}`,
                        e.error
                    );
                    const targetUrl = new URL(`https://${this.platform.document.location.host}${e.url}`);
                    targetUrl.searchParams.append(FORCE_RELOAD_QUERYPARAM_KEY, '1');
                    window.location.href = targetUrl.toString();
                }

                // force the next route change to be a browser reload
                // do this periodically after a configured amount of seconds to avoid having very old, outdated version on clients
                this.forcePageRefreshIfNeeded(e);
            }),
            filter<NavigationEnd>(e => e instanceof NavigationEnd),
            startWith({} as NavigationEnd),
            map(navigationEndEvent => {
                // map to our router state
                const routerState = serializeRouterState(
                    this.router.routerState.snapshot,
                    this.router.getCurrentNavigation(),
                    previousUrl
                );

                // store current URL for next navigation
                previousUrl = navigationEndEvent.url;

                return routerState;
            }),
            shareReplay({ bufferSize: 1, refCount: true })
        );

        this.routerStateSnapshot$
            .pipe(
                map((routerState, index) => {
                    // get the navigation count
                    return [routerState, index] as [PhxRouterStateSnapshot, number];
                })
            )
            .subscribe(([routerState, index]) => {
                // update routerstate property
                this.routerStateSnapshot = routerState;

                // check for first route
                if (this.router.navigated && this.isFirstRoute && index > 1) {
                    this.isFirstRoute = false;
                }

                // helper for `refreshCurrentRoute` function. cleaning up afterwards
                this.cleanupAfterRouteRefresh(routerState);
                this.cleanupAfterRouteReuse(routerState);

                // dispatch custom event to be consumed by injected javascript
                if (routerState.primaryPath !== '') {
                    this.dispatchRouteChangeCustomEvent();
                }

                this.startCustomStabilizationCheck();

                this.logOpenMacroTasks();
            });

        // Force a page refresh if we were served as a cached snapshot via back-forward cache (router is not triggered in this case)
        // See https://github.com/angular/angular/issues/39382
        if (this.platform.isBrowser) {
            window.addEventListener('pageshow', function (event) {
                if (event.persisted) {
                    window.location.reload();
                }
            });
        }

        // Log if the page was reloaded
        if (this.platform.isBrowser) {
            try {
                const entries = performance.getEntriesByType('navigation');
                entries.forEach(entry => {
                    if (entry['type'] === 'reload') {
                        this.log.info('Page was reloaded');
                    }
                });
            } catch (e) {}
        }

        if (this.platform.isBrowser) {
            window['phxTestability'] = {
                whenStable: (callbackFn: any) => {
                    this.isStable$.pipe(delay(0), filter(Boolean), take(1)).subscribe(() => {
                        callbackFn();
                    });
                }
            };
        }
    }

    back(fallbackUrl?: string) {
        if (!this.isFirstRoute) {
            this.location.back();
        } else {
            this.router.navigateByUrl(fallbackUrl || '/');
        }
    }

    refreshCurrentRoute(fromAppRoot = false) {
        this.router.navigate([], {
            queryParams: { [FORCE_REFRESH_QUERYPARAM_KEY]: fromAppRoot ? '2' : '1', bot: null },
            queryParamsHandling: 'merge',
            preserveFragment: true,
            skipLocationChange: true
        });
    }

    navigateWithForcedRouteReuse(newUrl: string, replaceUrl = true) {
        // only needed if the URL has actually changed
        const currentUrl = this.router.url;
        if (currentUrl === newUrl) {
            return;
        }

        const urlTree = this.router.parseUrl(newUrl);
        urlTree.queryParams[FORCE_REUSE_QUERYPARAM_KEY] = 1;

        this.router.navigateByUrl(urlTree, { replaceUrl });
    }

    navigateExternal(url: string) {
        window.location.href = url;
    }

    dispatchRouteChangeCustomEvent(url?: URL) {
        if (this.platform.isServer) {
            return;
        }

        if (!url) {
            url = new URL(location.href);
        }

        // ignore certain special routes
        if (url.search.includes(FORCE_REUSE_QUERYPARAM_KEY) || url.search.includes(FORCE_REFRESH_QUERYPARAM_KEY)) {
            return;
        }

        const isOnlyRefresh = this.router.getCurrentNavigation()?.extras?.state?.onlyRefresh;

        // Getting the appRef instance here as the app does not start when injected in the constructor directly
        this.isStable$
            .pipe(
                filter(isStable => isStable),
                take(1)
            )
            .subscribe(() => {
                window.dispatchEvent(
                    new CustomEvent(isOnlyRefresh ? 'phx-routerefresh-stable' : 'phx-routechange-stable', {
                        detail: url
                    })
                );
            });

        window.dispatchEvent(new CustomEvent(isOnlyRefresh ? 'phx-routerefresh' : 'phx-routechange', { detail: url }));
    }

    startCustomStabilizationCheck() {
        // Custom stabilization check (so ignore setTimeout and setInterval macro tasks as they are not relevant to our use cases (i.e. scroll restoration))
        this.ngZone.runOutsideAngular(() => {
            if (this.customStabilizationCheckInProgress) {
                return;
            }

            const isCustomStable$ = this.customStabilizationCheckIntervalInMs
                ? interval(this.customStabilizationCheckIntervalInMs, leaveNgZone(this.ngZone)).pipe(
                      map(() => {
                          const taskTrackingZone = (this.ngZone as any)._inner._parent._properties.TaskTrackingZone;
                          const openMacroTasks = taskTrackingZone?.getTasksFor('macroTask') as any[];

                          if (!openMacroTasks?.length) {
                              return true;
                          }

                          // we ignore non-http tasks
                          if (
                              openMacroTasks.every(
                                  task => task.source === 'setInterval' || task.source === 'setTimeout'
                              )
                          ) {
                              return true;
                          }

                          return false;
                      })
                  )
                : of(false);

            this.customStabilizationCheckInProgress = true;
            combineLatest([isCustomStable$, this.injector.get(ApplicationRef).isStable.pipe(startWith(false))])
                .pipe(
                    filter(([isCustomStable, isAngularStable]) => isCustomStable || isAngularStable),
                    take(1)
                )
                .subscribe(() => {
                    this.customStabilizationCheckInProgress = false;
                    this.isStable$.next(true);
                });
        });
    }

    // helper for `refreshCurrentRoute` function. cleaning up afterwards
    private cleanupAfterRouteRefresh(routerState: PhxRouterStateSnapshot) {
        if (routerState.queryParamMap.has(FORCE_REFRESH_QUERYPARAM_KEY)) {
            const currentQueryParams = objectFromParamMap(routerState.queryParamMap);
            delete currentQueryParams[FORCE_REFRESH_QUERYPARAM_KEY];

            // noop route change to remove special force_refresh route from history
            this.router.navigate([], {
                queryParams: { ...currentQueryParams },
                preserveFragment: true,
                skipLocationChange: true,
                replaceUrl: true
            });
        }
    }

    // helper for `navigateWithForcedRouteReuse` function. cleaning up afterwards
    private cleanupAfterRouteReuse(routerState: PhxRouterStateSnapshot) {
        if (routerState.queryParamMap.has(FORCE_REUSE_QUERYPARAM_KEY)) {
            const currentQueryParams = objectFromParamMap(routerState.queryParamMap);
            delete currentQueryParams[FORCE_REUSE_QUERYPARAM_KEY];

            // noop route change to remove special force_reuse route from history
            this.router.navigate([], {
                queryParams: { ...currentQueryParams },
                preserveFragment: true,
                skipLocationChange: false,
                replaceUrl: true,
                state: { onlyRefresh: true }
            });
        }
    }

    private forcePageRefreshIfNeeded(event: any) {
        if (
            this.forcedPageRefreshIntervalInSeconds > 0 &&
            event instanceof NavigationStart &&
            this.platform.isBrowser &&
            !event.url.includes(FORCE_REUSE_QUERYPARAM_KEY) &&
            !event.url.includes(FORCE_REFRESH_QUERYPARAM_KEY) &&
            !event.url.includes('#') &&
            (Date.now() - this.startupTimestamp) / 1000 > this.forcedPageRefreshIntervalInSeconds
        ) {
            const loggerService = this.injector.get(LoggerService);
            loggerService?.info(
                `[NAV] Forced page refresh after an uptime of ${
                    (Date.now() - this.startupTimestamp) / 1000
                }s to target url: '${event.url}'`
            );
            const targetUrl = new URL(`https://${this.platform.document.location.host}${event.url}`);
            window.location.href = targetUrl.toString();
        }
    }

    private logOpenMacroTasks() {
        // Log long running macro tasks to debug what is blocking Angular stabilization
        // (Check https://dev.to/krisplatis/how-to-find-out-why-angular-ssr-hangs-track-ngzone-tasks-4nbd)
        if (this.isStabilizationLogEnabled) {
            this.stabilizationLogTimeoutId = this.ngZone.runOutsideAngular(() => {
                return setTimeout(() => {
                    const taskTrackingZone = (this.ngZone as any)._inner._parent._properties.TaskTrackingZone;

                    const openMacroTasks = taskTrackingZone.getTasksFor('macroTask');
                    if (openMacroTasks.length) {
                        openMacroTasks.forEach(t => {
                            let customInfo = '';
                            if (t.source === 'XMLHttpRequest.send') {
                                customInfo = `${t.source} with '${t.data.url}'\n`;
                            }

                            if (t.source === 'setInterval' || t.source === 'setTimeout') {
                                customInfo = `${t.source} with '${t.data.delay}'\n`;
                            }

                            const msg = `Still open macro task after ${this.stabilizationLogWaitInMs}ms: ${customInfo}${t.creationLocation.stack}`;

                            this.log.info(msg);
                        });
                    }
                }, this.stabilizationLogWaitInMs);
            });
        }
    }
}
