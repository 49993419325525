import { ActivatedRouteSnapshot, ParamMap, Params, UrlSegment } from '@angular/router';

export const AUX_PATH_REGEX = /\(.*:[^?&]+\)/g;

export function createNavCommands(url: string): string[] {
    return url?.replace(/^\//, '')?.split('/');
}

/**
 * Extracts the path of the primary route of the uri - without aux urls, without parameters
 */
export function extractPrimaryPath(url: string) {
    // get rid of query and matrix params
    const paramsRegex = /[;?#].*/;

    return url.replace(AUX_PATH_REGEX, '').replace(paramsRegex, '');
}

/**
 * Extracts the query parameters of the uri
 */
export function extractQueryParams(url: string): Params {
    const primaryUrl = url.replace(AUX_PATH_REGEX, '');
    const paramsMatch = primaryUrl.match(/\?(.*)/);

    if (!paramsMatch) {
        return null;
    } else {
        return paramsMatch[1]
            .split('&')
            .map(p => p.split('='))
            .reduce((params, [pKey, pValue]) => {
                // if there are more params with the same key, group them in an array
                if (params[pKey]) {
                    if (!Array.isArray(params[pKey])) {
                        params[pKey] = [params[pKey]];
                    }
                    (params[pKey] as any[]).push(pValue);
                } else {
                    params[pKey] = pValue;
                }
                return params;
            }, {} as Params);
    }
}

/**
 * Extracts the path of the uri of a given auxiliary route from url
 */
export function extractAuxPath(url: string, outlet: string) {
    const auxUrlRegex = new RegExp(`\\(${outlet}:(.*)\\)`);

    return (url.match(auxUrlRegex) || [])[1];
}

export function routeInNonPrimaryOutlet(route: ActivatedRouteSnapshot) {
    if (route == null) {
        return false;
    } else if (route.outlet !== 'primary') {
        return true;
    }
    return routeInNonPrimaryOutlet(route.parent);
}

export function encodeUrlFragment(stringToUse: string) {
    return stringToUse
        .toLowerCase()
        .replace(/\s/g, '-')
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss')
        .replace(/[^\w-]+/g, '');
}

export function constructFullRouteUrl(route: ActivatedRouteSnapshot): UrlSegment[] {
    const segments = route.url.filter(segment => segment);

    if (!route.parent) {
        return segments;
    }

    return [...constructFullRouteUrl(route.parent), ...segments];
}

export function objectFromParamMap(pm: ParamMap) {
    const singleOrArray = (arrayValue: string[]) => {
        return arrayValue?.length > 1 ? arrayValue : arrayValue?.[0];
    };

    return pm.keys.reduce((paramObject, key) => ({ ...paramObject, [key]: singleOrArray(pm.getAll(key)) }), {});
}
