import { ContentItemTypeMap } from '@phx/core';
import { FallbackComponent } from './fallback/fallback.component';
import { RawHtmlComponent } from './raw-html/raw-html.component';

export const contentfulBaseComponentMap = new Map<string, any>([
    ['__fallback__', { component: FallbackComponent }],
    ['componentRawHtml', { component: RawHtmlComponent }]
]) as ContentItemTypeMap;

export const contentfulBaseMappedComponents = [FallbackComponent, RawHtmlComponent];
