// props to https://medium.com/@AustinMatherne/angular-let-directive-a168d4248138
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface VarContext<T> {
    phxVar: T;
}

@Directive({
    selector: '[phxVar]'
})
export class VarDirective<T> {
    private context: VarContext<T> = { phxVar: null };

    constructor(viewContainer: ViewContainerRef, templateRef: TemplateRef<VarContext<T>>) {
        viewContainer.createEmbeddedView(templateRef, this.context);
    }

    @Input()
    set phxVar(value: T) {
        this.context.phxVar = value;
    }
}
