import { AnimationDriver } from '@angular/animations/browser';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { localConfigOverrides } from '../../configs/local-configuration-overrides';
import * as localConfiguration from '../../configs/local-configuration.json';
import { configurationAppInitializerFn } from './configuration/configuration-app-initializer-factory';
import { LOCAL_CONFIG, LOCAL_CONFIG_OVERRIDES } from './configuration/configuration-types';
import { ConfigurationService } from './configuration/configuration.service';
import { EmptyLazyModuleHelper } from './dynamic-component/empty-lazy-module-helper.service';
import { LazyModuleHelper } from './dynamic-component/lazy-module-helper.service';
import { BasicAuthHelperComponent } from './pages/basic-auth-helper/basic-auth-helper.component';
import { HealthCheckComponent } from './pages/health-check/health-check.component';
import { ManageComponent } from './pages/manage/manage.component';
import { ImageFormatSupportService, NavigationService } from './service';
import { CustomStabilizationHttpInterceptor } from './service/custom-stabilization.interceptor.service';
import { ServerSideRelativeUrlHttpInterceptor } from './universal/server-side-relative-url-http-interceptor.service';
import { PhxAnimationDriverFactory } from './utils/disable-animations';
import { throwIfAlreadyLoaded } from './utils/module-import-guard';

@NgModule({
    imports: [RouterModule, CommonModule, FormsModule],
    declarations: [HealthCheckComponent, BasicAuthHelperComponent, ManageComponent],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: configurationAppInitializerFn,
            multi: true,
            deps: [ConfigurationService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerSideRelativeUrlHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomStabilizationHttpInterceptor,
            multi: true
        },
        {
            provide: LazyModuleHelper,
            useClass: EmptyLazyModuleHelper
        },
        {
            provide: LOCAL_CONFIG_OVERRIDES,
            useValue: localConfigOverrides
        },
        {
            provide: LOCAL_CONFIG,
            useValue: localConfiguration['default']
        },
        {
            provide: AnimationDriver,
            useFactory: PhxAnimationDriverFactory
        },
        {
            provide: APP_INITIALIZER,
            useFactory(navService: NavigationService) {
                return function initNavService() {
                    navService.init();
                };
            },
            multi: true,
            deps: [NavigationService]
        },
        {
            provide: APP_INITIALIZER,
            useFactory(imageFormatSupportService: ImageFormatSupportService) {
                return function initImageFormatSupport() {
                    imageFormatSupportService.init();
                };
            },
            multi: true,
            deps: [ImageFormatSupportService]
        }
    ]
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
