import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError } from '@phx/core';

@Injectable({
    providedIn: 'root'
})
export class MerchantCockpitCustomErrorOverridesService {
    customErrorOverrides(response: HttpErrorResponse, apiError: ApiError) {
        ignoreErrorIfNeeded(response, apiError);
        hideErrorIfNeeded(response, apiError);
        overrideLogLevelForKnownErrors(response, apiError);
    }
}

function ignoreErrorIfNeeded(response: HttpErrorResponse, apiError: ApiError) {
    if (response.status === 419 && response.url.match(/\/autologin$/)) {
        apiError.ignore = true;
    }
}

function hideErrorIfNeeded(response: HttpErrorResponse, apiError: ApiError) {
    // autologin could get a 401 if we e.g. purged all sessions in the backend because we had to logout all users
    // the user is in a correctly loggedout state then anyway, no need to show the error and block the user
    if (response.status === 401 && response.url.match(/\/autologin$/)) {
        apiError.hide = true;
    }
}

function overrideLogLevelForKnownErrors(response: HttpErrorResponse, apiError: ApiError) {
    switch (response.status) {
        case 401:
        case 402:
            // full or semi auth required
            apiError.overrideLogLevel = 'info';
            break;
    }
}
