import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { convertNativeDateToNgbDate, convertNgbDateToNativeDate } from 'src/app/utils/date-utils';

export interface DateChangeEvent {
    fromDate: Date | null;
    toDate: Date | null;
}

@Component({
    selector: 'phx-range-datepicker',
    templateUrl: './range-datepicker.component.html',
    styleUrls: ['./range-datepicker.component.scss']
})
export class RangeDatepickerComponent implements OnChanges {
    // NOTE: Taken from https://ng-bootstrap.github.io/#/components/datepicker/examples#range-popup and adapted

    @ViewChild(NgbInputDatepicker)
    datepicker: NgbInputDatepicker;

    @ViewChild('dpFromDate')
    fromDateInput: ElementRef<HTMLInputElement>;

    @ViewChild('dpToDate')
    toDateInput: ElementRef<HTMLInputElement>;

    @Input()
    initialFromDate: Date | null;

    @Input()
    initialToDate: Date | null;

    @Output()
    dateChange = new EventEmitter<DateChangeEvent>();

    fromDate: NgbDate | null;
    toDate: NgbDate | null;
    hoveredDate: NgbDate | null = null;

    constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['initialFromDate']?.currentValue) {
            this.fromDate = NgbDate.from(convertNativeDateToNgbDate(changes['initialFromDate'].currentValue));
        }

        if (changes['initialToDate']?.currentValue) {
            this.toDate = NgbDate.from(convertNativeDateToNgbDate(changes['initialToDate'].currentValue));
        }
    }

    onDateChange() {
        setTimeout(() => {
            // NOTE: Taking the input's value instead of the component properties, since we need to handle invalid entered dates.
            // NgbDate always only accepts valid dates, so for an invalid entered date we could only:
            // a) Set the date to null and propagate it correctly as null via the EventEmitter.
            // But this would immediately clear the input and thus breaks the ability to type in dates manually.
            // b) Keep the previous correct date instead, not propagating this fact correctly via the EventEmitter,
            // but we are able to keep the invalid text in the input. Then propagate the date as null based on the input value via the EventEmitter.
            // This approach is chosen now.
            const inputFromDate = this.validateInput(null, this.fromDateInput.nativeElement.value);
            const inputToDate = this.validateInput(null, this.toDateInput.nativeElement.value);

            this.dateChange.emit({
                fromDate: inputFromDate && convertNgbDateToNativeDate(this.fromDate),
                toDate: inputToDate && convertNgbDateToNativeDate(this.toDate)
            });
        });
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && (date.equals(this.fromDate) || date.after(this.fromDate))) {
            this.toDate = date;
            this.datepicker.close();
        } else {
            this.toDate = null;
            this.fromDate = date;
        }

        this.onDateChange();
    }

    isHovered(date: NgbDate) {
        return (
            this.fromDate &&
            !this.toDate &&
            this.hoveredDate &&
            date.after(this.fromDate) &&
            date.before(this.hoveredDate)
        );
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return (
            date.equals(this.fromDate) ||
            (this.toDate && date.equals(this.toDate)) ||
            this.isInside(date) ||
            this.isHovered(date)
        );
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);

        if (parsed.year < 2000) {
            // NOTE: workaround since entering the year manually is broken with this library,
            // since it parses the first digit as year immediately, so 01.01.2 becomes 01.01.1920
            // and it will not let you type the full year that way
            return currentValue;
        }

        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }
}
